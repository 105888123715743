import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function FilterComponent({ setSortAndFilter }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [name_direction_sort, setNameDirectionSort] = React.useState('')
    const [email_direction_sort, setEmailDirectionSort] = React.useState('')
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onhandleSortBy = (filter_type) => {
        let nameDirectionSort = '';
        let emailDirectionSort = ''

        if (filter_type === 'first_name') {
            if (name_direction_sort === '') {
                nameDirectionSort = 'asc';
            } else if (name_direction_sort === 'asc') {
                nameDirectionSort = 'desc';
            } else {
                nameDirectionSort = '';
            }
        }

        if (filter_type === 'email') {
            if (email_direction_sort === '') {
                emailDirectionSort = 'asc';
            } else if (email_direction_sort === 'asc') {
                emailDirectionSort = 'desc';
            } else {
                emailDirectionSort = '';
            }
        }

        setSortAndFilter({
            filter_type: filter_type,
            name_sort_order: filter_type === 'first_name' ? nameDirectionSort : '',
            email_sort_order: filter_type === 'email' ? emailDirectionSort : ''
        });

        setNameDirectionSort(nameDirectionSort);
        setEmailDirectionSort(emailDirectionSort)
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Filter">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        className='APP-COLOR-2'
                        sx={{ ml: 2, p: 1 }}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <FilterAltIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => onhandleSortBy('first_name')}>
                    Sort By Name  &nbsp; {name_direction_sort === 'asc' ? <ArrowUpwardIcon /> : name_direction_sort === 'desc' ? <ArrowDownwardIcon /> : ''}
                </MenuItem>
                <MenuItem onClick={() => onhandleSortBy('phone_number')}>
                    Sort By Phone Number
                </MenuItem>
                <MenuItem onClick={() => onhandleSortBy('email')}>
                    Sort By Email &nbsp; {email_direction_sort === 'asc' ? <ArrowUpwardIcon /> : email_direction_sort === 'desc' ? <ArrowDownwardIcon /> : ''}
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}