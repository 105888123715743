import { getScansCount, getUsersCount } from "../utils/api";

export const getTotalUsersAndScansInCompany = () => {
    return async dispatch => {
        try {
            const [user_count_response, users_scans_response] = await Promise.all([
                getUsersCount(),
                getScansCount()
            ])
            dispatch({
                type: 'user_count_success',
                payload: [user_count_response?.data?.data?.total_users ? user_count_response?.data?.data?.total_users : 0,
                users_scans_response?.data?.data?.total_scans ? users_scans_response?.data?.data?.total_scans : 0]
            })
        } catch (error) {
            dispatch({
                type: 'FETCH_ERROR',
                payload: error.message
            });
        }
    };
};

export const clearReduxForHomeComponent = () => {
    return async dispatch => {
        dispatch({
            type: 'LOGOUT',
        });
    }
}

export const clearReduxDataForHomeComponent = () => {
    return async dispatch => {
        dispatch({
            type: 'CLAER_CACHE_REDUX_HOME',
        });
    }
}