import React, { useEffect, useState } from 'react'
import { getCompanyMetaData } from '../../utils/api';
import Table from '../../widget/Table';

function CompanyMetaData() {
    const [companyMetaData, setCompanyMetaData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            try {
                const responce = await getCompanyMetaData()
                setCompanyMetaData(responce?.data?.data?.metadata)
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        })()
    }, [])

    const columns = [
        {
            field: 'Metadata Value',
            headerName: 'Metadata Value',
            width: 440,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.metadata_value },
        },
        {
            field: 'Metadata Type',
            headerName: 'Metadata Type',
            width: 440,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.metadata_type },
        },
        {
            field: ' Metadata Key',
            headerName: ' Metadata Key',
            width: 330,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.metadata_key },
        },
    ];

    return (
        <div>
            <div>
                <div className='d-flex justify-between flex-direction-custom g-3'>
                    <h3><strong>Company MetaData</strong></h3>
                    <input style={{ padding: 10, borderRadius: 5, border: '1px solid skyblue' }} placeholder='Search' onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
                <div className="b-r-10 p-10 m-t-10" style={{ backgroundColor: 'white'}}> <Table rows={companyMetaData} columns={columns}loading={loading} /></div>
            </div>
        </div>
    )
}

export default CompanyMetaData