import { FaUser } from "react-icons/fa";
import { BiHome } from "react-icons/bi";
import SettingsIcon from '@mui/icons-material/Settings';

const routes = [
    {
        path: "/dashboard/home",
        name: "Home",
        icon: <BiHome sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/users",
        name: "Users",
        icon: <FaUser sx={{height:20, width:20}}/>,
    },
    {
        path: "/dashboard/setting",
        name: "Setting",
        icon: <SettingsIcon sx={{height:20, width:20}}/>,
    },
];

export default routes