import React, { useEffect, useState } from 'react'
import { getCompanyCrops } from '../../utils/api';
import { TextField } from '@mui/material';
import Table from '../../widget/Table';

function CompanyCrops() {
    const [companyCrops, setCompanyCrops] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true)
    const [filteredData, setFilterdData] = useState([])

    useEffect(() => {
        (async () => {
            try {
                const responce = await getCompanyCrops()
                setCompanyCrops(responce?.data?.data.crops)
                setFilterdData(responce?.data?.data.crops)
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        })()
    }, [])

    useEffect(() => {
        onhandleSearch();
    }, [searchTerm]);

    const onhandleSearch = () => {
        if (companyCrops) {
            const filteredData = companyCrops.filter((crop) => (
                crop.crop.toLowerCase().includes(searchTerm.toLowerCase()) ||
                crop.variety.toLowerCase().includes(searchTerm.toLowerCase())
            ))
            setFilterdData(filteredData);
        }
    };

    const columns = [
        {
            field: 'Crop',
            headerName: 'Crop',
            width: 250,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.crop },
        },
        {
            field: 'Variety',
            headerName: 'Variety',
            width: 250,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.variety },
        },
    ];

    return (
        <div>
            <div>
                <div className='d-flex justify-between flex-direction-custom g-3'>
                    <h3><strong>Crops</strong></h3>
                    <input style={{ padding: 10, borderRadius: 5, border: '1px solid skyblue' }} placeholder='Search' onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
                <div className="b-r-10 p-10 m-t-10" style={{ backgroundColor: 'white'}}><Table rows={filteredData} columns={columns} loading={loading} /></div>
            </div>
        </div>
    )
}

export default CompanyCrops