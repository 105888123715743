import { Box, Skeleton } from '@mui/material'
import React from 'react'

function LoadingSkeleton() {
  return (
    <Box sx={{ p: 2 }}>

      <div style={{ backgroundColor: 'white', borderRadius: '18px' }}>
        <div style={{ borderTopLeftRadius: '18px', borderTopRightRadius: '18px' }} animation="wave" variant="rectangular" height={75} >
          <div style={{ padding: 5, display: 'flex', justifyContent: 'start', gap: 5 }}>
            {/* <Typography variant="h1">{ <Skeleton /> }</Typography> */}
            <Skeleton sx={{ borderRadius: '50%' }} animation="wave" variant="rectangular" height={30} width={30} />
            <div style={{display: 'flex',  flexDirection: 'column', gap: 5}}>
              <Skeleton sx={{ borderRadius: '10px' }} animation="wave" variant="rectangular" height={20} width={250} />
              <Skeleton sx={{ borderRadius: '10px' }} animation="wave" variant="rectangular" height={20} width={200} />
            </div>
          </div>
        </div>
        <div style={{ borderBottomLeftRadius: '18px', borderBottomRightRadius: '18px', margin: 5 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between'  }} className='flex-direction-custom'>
            <Skeleton sx={{ borderRadius: '10px', m: 1 }} animation="wave" variant="rectangular" className='card-Width' height={50} />
            <Skeleton sx={{ borderRadius: '10px', m: 1}} animation="wave" variant="rectangular" className='card-Width' height={50} />
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }} className='flex-direction-custom'>
        <Skeleton sx={{ borderRadius: '18px', mt: 2 }} animation="wave" variant="rectangular" className='card-Width' height={350} />
        <Skeleton sx={{ borderRadius: '18px', mt: 2 }} animation="wave" variant="rectangular" className='card-Width' height={350} />
      </div>

    </Box>
  )
}

export default LoadingSkeleton