import { Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import { createNewUser, updateExistingUser } from '../../utils/api';
import AppMessageModel from '../../widget/AppMessageModel';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CircularLoadingPopUp from '../../widget/CircularLoadingPopUp';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid aliceblue',
  boxShadow: 24,
  // borderRadius: 3,
  p: 4,
};

function CreateAndUpdateUser({ open, setOpen, onUpdate, setOnUpdate, isAddOrEditUser, setIsAddOrEditUser }) {
  // const handleOpen = () => setOpen(true);
  const [switchEmail, setSwitchEmail] = useState('email')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [successfulMessage, setSuccessfulMessage] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [validationError, setValidationError] = useState('')
  const [buttonDisable, setButtonDisable] = useState(false)

  useEffect(() => {
    (() => {
      if (onUpdate) {
        setFirstName(onUpdate.first_name)
        setLastName(onUpdate.last_name)
        setEmail(onUpdate.email)
        setPhoneNumber(onUpdate.phone_number)
      }
    })()
  }, [onUpdate])

  const handleClose = () => {
    setOpen(false)
    setSwitchEmail('email')
    setOnUpdate('')
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhoneNumber('')
    setValidationError('')
    setButtonDisable(false)
  };

  function isValidEmail(email) {
    var emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email);
  }

  const onHandleEmail = (e) => {
    const email = e.target.value;
    if (email.trim() === '') {
      setValidationError('');
      setButtonDisable(false);
    } else if (isValidEmail(email)) {
      setValidationError('');
      setButtonDisable(false);
    } else {
      setValidationError('Invalid Email');
      setButtonDisable(true);
    }
    setEmail(email);
  };
  

  const isValidPhone = (phoneNo) => {
    let pattern = /^[6-9]{1}[0-9]{9}$/;
    return pattern.test(phoneNo);
  }

  const onHandlePhone = (e) => {
    const phoneNumber = e.target.value;
    if (phoneNumber.trim() === '') {
      setValidationError('');
      setButtonDisable(false);
    } else if (isValidPhone(phoneNumber)) {
      setValidationError('');
      setButtonDisable(false);
    } else {
      setValidationError('Invalid Phone Number');
      setButtonDisable(true);
    }
    setPhoneNumber(phoneNumber);
  };
  


  const onHandleAddUser = async (event) => {
    event.preventDefault()
    setLoading(true)
    const data = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email: email,
      company_id: localStorage.getItem('company_id'),
      user_type: "FARMER"
    }
    try {
      const responce = await createNewUser(data)
      if (responce?.status === 200) {
        setSuccessfulMessage(true)
        setTitle('User added !!!')
        setMessage(responce?.data?.message)
        setOpen(false)
        setLoading(false)
        setIsAddOrEditUser(!isAddOrEditUser)
      }
    } catch (error) {
      setSuccessfulMessage(true)
      setTitle('Error !!!')
      setMessage('Something went wrong. Please try again later !!!')
      setOpen(false)
      setLoading(false)
    }
    setOnUpdate('')
  }

  const onHandleUpdateUser = async (event) => {
    event.preventDefault()
    setLoading(true)
    const data = {
      first_name: firstName,
      last_name: lastName,
      firebase_user_id: onUpdate.firebase_user_id
    }
    try {
      const responce = await updateExistingUser(data)
      if (responce?.status === 200) {
        setSuccessfulMessage(true)
        setTitle('User Updated !!!')
        setMessage(responce?.data?.message)
        setOpen(false)
        setIsAddOrEditUser(!isAddOrEditUser)
      }
    } catch (error) {
      setSuccessfulMessage(true)
      setTitle('Error !!!')
      setMessage('Something went wrong. Please try again later !!!')
      setOpen(false)
    }
    setLoading(false)
    setOnUpdate('')
  }

  return (
    <div>
      <AppMessageModel
        open={successfulMessage}
        setOpen={setSuccessfulMessage}
        title={title}
        message={message}
      />

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div>
            {!loading ?
              <div>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2">
                  <div>{onUpdate ? <div className='m-t-10'>
                    <h3>Update {firstName} {lastName}</h3>
                    <h6 className='m-a-10'>{email ? <span className='d-flex g-3'><MailOutlineIcon /> {email}</span> : <span className='d-flex g-3'><CallIcon /> {phoneNumber}</span>}</h6>
                  </div>
                    : <strong>Create New User</strong>}</div>
                </Typography>

                <form onSubmit={onUpdate ? onHandleUpdateUser : onHandleAddUser}>
                  <div>
                    <div>
                      <div>
                        <TextField
                          required
                          className='w-100'
                          label="Enter First Name"
                          type='text'
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                        />
                      </div>
                      <div>
                        <TextField
                          required
                          className='w-100'
                          label="Enter Last Name"
                          type='text'
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                        />
                      </div>
                    </div>

                    {!onUpdate && <div>
                      <div>
                        <FormControl>
                          <RadioGroup
                            row
                            style={{ marginTop: '10px' }}
                          >
                            <FormControlLabel value="email" checked={switchEmail === 'email'} onClick={() => setSwitchEmail('email')} control={<Radio />} label="Email" />
                            <FormControlLabel value="phone" checked={switchEmail === 'phone'} onClick={() => setSwitchEmail('phone')} control={<Radio />} label="Phone Number" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div>
                        {switchEmail === "email" ? <TextField
                          required
                          className='w-100'
                          label="Enter Email"
                          type='text'
                          value={email}
                          onChange={(e) => onHandleEmail(e)}
                          style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                        /> :
                          <TextField
                            required
                            className='w-100'
                            label="Enter Phone Number"
                            type='number'
                            max={10}
                            value={phoneNumber}
                            onChange={(e) => onHandlePhone(e)}
                            style={{ border: '1px solid aliceblue', marginTop: '10px' }}
                          />}
                      </div>
                      <p className='red'>{validationError}</p>
                    </div>}
                  </div>

                  <Box className='d-flex justify-end'>
                    <Button disabled={buttonDisable} className={buttonDisable ? 'm-t-20 bg-lightgray' : 'm-t-20 bg-dark-blue'} style={{ color: 'white', border: 'none', padding: '5px 15px' }} type='submit'>Submit</Button>
                  </Box>
                </form>

              </div>
              : <CircularLoadingPopUp />}
          </div>

        </Box>
      </Modal>
    </div>
  )
}

export default CreateAndUpdateUser