import React, { useEffect, useState } from 'react'
import { getCompanyInvoice, getPaymentHistroy } from '../../utils/api';
import Table from '../../widget/Table';
import moment from 'moment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MessageModel from '../../widget/MessageModel';
import CircularLoadingPopUp from '../../widget/CircularLoadingPopUp';


function PaymentHistroy() {
  const [paymentHistroy, setPaymentHistroy] = useState([])
  const [filteredData, setFilterdData] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true)
  const [isOpenModel, setIsOpenModel] = useState(false)
  const [modelType, setModelType] = useState('')
  const [pdfLink, setPdfLink] = useState('')
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        const responce = await getPaymentHistroy()
        setPaymentHistroy(responce?.data?.data?.paymentHistory)
        setFilterdData(responce?.data?.data?.paymentHistory)
        console.log(responce?.data?.data?.paymentHistory);
        setLoading(false)
      } catch (error) {
        console.error(error);
      }
    })()
  }, [])

  useEffect(() => {
    onhandleSearch();
  }, [searchTerm]);

  const onhandleSearch = () => {
    if (paymentHistroy) {
      const filteredData = paymentHistroy.filter((payment) => (
        payment.payment_description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(payment.payment_gst).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(payment.payment_total).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(payment.payment_amount).toLowerCase().includes(searchTerm.toLowerCase()) || 
        moment(payment.payment_date).format('DD MMM, YYYY hh:mm A').toLowerCase().includes(searchTerm.toLowerCase())

      ))
      setFilterdData(filteredData);
    }
  };

  const getInvoice = async (payment_id) => {
    setIsInvoiceLoading(true)
    const result = await getCompanyInvoice(payment_id)
    console.log(result.data);
    // window.open(URL.createObjectURL(result.data));
    const pdfBlob = new Blob([result.data], { type: 'application/pdf' });
    window.open(URL.createObjectURL(pdfBlob));
    setIsInvoiceLoading(false)
  }

  const columns = [
    {
      field: 'Description',
      headerName: 'Description',
      width: 300,
      headerClassName: 'table-header',
      editable: false,
      valueGetter: (params) => { return params.row.payment_description },
    },
    {
      field: 'payment_date',
      headerName: 'Payment Date',
      width: 220,
      headerClassName: 'table-header',
      editable: false,
      valueGetter: (params) => { return moment(params.row.payment_date).format('DD MMM, YYYY hh:mm A') },
    },
    {
      field: 'Payment Amount',
      headerName: 'Payment Amount',
      width: 190,
      headerClassName: 'table-header',
      editable: false,
      valueGetter: (params) => { return `₹ ${params.row.payment_amount}` },
    },
    {
      field: 'Payment GST',
      headerName: 'Payment GST',
      width: 190,
      headerClassName: 'table-header',
      editable: false,
      valueGetter: (params) => { return `₹ ${params.row.payment_gst}` },
    },
    {
      field: 'Payment Total',
      headerName: 'Payment Total',
      width: 190,
      headerClassName: 'table-header',
      editable: false,
      valueGetter: (params) => { return `₹ ${params.row.payment_total}` },
    },
    {
      field: 'Invoice',
      headerName: 'Invoice',
      width: 120,
      headerClassName: 'table-header',
      editable: false,
      renderCell: (params) => (
        <PictureAsPdfIcon className='APP-COLOR cursor' onClick={() => getInvoice(params.row.id)} />
      ),
    },
  ];

  return (
    <div>
      {isInvoiceLoading && <CircularLoadingPopUp/>}
      {isOpenModel && <MessageModel open={isOpenModel} onClose={setIsOpenModel} type={modelType} pdfLink={pdfLink} setPdfLink={setPdfLink} />}
      <div>
        <div className='d-flex justify-between flex-direction-custom g-3'>
          <h3><strong>Payment History</strong></h3>
          <input style={{ padding: 10, borderRadius: 5, border: '1px solid skyblue' }} placeholder='Search' onChange={(e) => setSearchTerm(e.target.value)} />
        </div>
        <div className="b-r-10 p-10 m-t-10" style={{ backgroundColor: 'white'}}>
          <Table rows={filteredData} columns={columns} loading={loading} />
        </div>
      </div>
    </div>
  )
}

export default PaymentHistroy