import React, { useEffect, useMemo, useState } from 'react'
import { Card, Box, FormControl, MenuItem, Select, CircularProgress } from '@mui/material'
import { AiOutlineScan } from 'react-icons/ai'
import { LocationOn, PeopleAlt } from '@mui/icons-material';
import DateRangePicker from '../widget/DateRangePicker';
import StackedBarChart from '../charts/StackedBarChart';
import HeatMapChart from '../charts/HeatMapChart';
import { getAllCrops, getAllMachine, getHeatmapData, getScansCount, getScansDataUsingCrops, getUsersCount } from '../utils/api';
import LoadingHomePage from '../widget/LoadingHomePage';
import MapComponent from '../map/Map';
import AdvanceDropDown from '../widget/AdvanceDropDown';
import AdvancedSubGropuDropDown from '../widget/AdvancedSubGroupDropdown';
import DateWithGradeChart from './users/DateWithGradeChart';
import { useDispatch, useSelector } from 'react-redux';
import QuantityVerseDateGraph from './users/QuantityVerseDateGraph';
import { onGetFormatedDateRange } from '../utils/common';

const Home = () => {
  const home_redux = useSelector(state => state.home)
  const dispatch = useDispatch()
  const [dateRange, setDateRange] = useState('')
  const [cropsList, setCropsList] = useState([])
  const [unique_crop_list, setUniqueCropList] = useState([])
  const [variety_list, setVarietyList] = useState([])
  const [machineList, setMachineList] = useState([])
  const [numberOfusers, setNumberOfUsers] = useState(0)
  const [totalScansCount, setTotalScansCount] = useState(0)
  const [heatmapSeriesList, setHeatMapSeriesList] = useState([])
  const [selectedDateOption, setSelectedDateOption] = useState('this_week');
  const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);
  const [selectedCropItem, setSelectedCropItem] = useState([])
  const [selectedMachine, setSelectedMachine] = useState([])
  const [selected_veriety, setSelectedVeriety] = useState([])
  const [loading, setLoading] = useState(false)
  const [customDateRange, setCustomDateRange] = useState({ startDate: '', endDate: '' })
  const [cropCategoriesStack, setCropCategoriesStack] = useState([])
  const [serialStackBarData, setSerialStackBarData] = useState([])
  const [metaData, setMetaData] = useState([])
  const [heat_map_loading, setHeatMapLoading] = useState(false)
  const [crop_vs_scans_loading, setCropVsScansLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        if (!(!home_redux?.user_count && !home_redux?.user_scans_count && !home_redux?.machine_list?.length && !home_redux?.crop_list?.length)) {
          setCropsList(home_redux?.crop_list)
          setSelectedCropItem(home_redux?.crop_list || [])
          setSelectedVeriety(home_redux?.crop_list || [])
          setMachineList(home_redux?.machine_list)
          setSelectedMachine(home_redux?.machine_list || [])
          setNumberOfUsers(home_redux?.user_count)
          setTotalScansCount(home_redux?.user_scans_count)
          setHeatMapSeriesList(home_redux?.home_heat_map_data)
          setCropCategoriesStack(home_redux?.home_crop_vs_scans_data?.crop_Categories)
          setSerialStackBarData(home_redux?.home_crop_vs_scans_data?.serial_data)
          setMetaData(home_redux?.home_map_data)
          setDateRange(home_redux?.default_date_range)
          setTimeout(() => {
            setLoading(true)
            setHeatMapLoading(false)
            setCropVsScansLoading(false)
          }, [500])
        } else {
          const [userResponce, scansResponce, crops_response, machines_responce] = await Promise.all([
            getUsersCount(),
            getScansCount(),
            getAllCrops(),
            getAllMachine(),
          ])
          if (userResponce?.data?.data) {
            setNumberOfUsers(userResponce?.data?.data?.total_users)
            setTotalScansCount(scansResponce?.data?.data.total_scans)
          }

          if (crops_response?.data?.data) {
            setCropsList(crops_response?.data?.data?.crops)
            setSelectedCropItem(crops_response?.data?.data?.crops || [])
            setSelectedVeriety(crops_response?.data?.data?.crops || [])
          }

          if (machines_responce?.data?.data) {
            setMachineList(machines_responce?.data.data.machines_data)
            setSelectedMachine(machines_responce?.data?.data?.machines_data || [])
          }

          // store in redux
          if (crops_response?.data?.data && machines_responce?.data?.data) {
            dispatch({
              type: 'user_count_success',
              payload: [userResponce?.data?.data?.total_users ? userResponce?.data?.data?.total_users : 0,
              scansResponce?.data?.data?.total_scans ? scansResponce?.data?.data?.total_scans : 0]
            })
            dispatch({
              type: 'user_count_for_user_page',
              payload: [userResponce?.data?.data?.total_users ? userResponce?.data?.data?.total_users : 0,
              scansResponce?.data?.data?.total_scans ? scansResponce?.data?.data?.total_scans : 0]
            })
            dispatch({
              type: 'crop_machine_data',
              payload: [crops_response?.data?.data ? crops_response?.data?.data?.crops : [],
              machines_responce?.data?.data ? machines_responce?.data?.data?.machines_data : []]
            })
          }

          setLoading(true)
          onSetDefaultDateRange()
        }
      } catch (error) {
        console.error(error);
      }
    })()
  }, [])

  useEffect(() => {
    const uniqueObjects = cropsList?.length && cropsList.reduce((unique, currentObj) => {
      const isDuplicate = unique.some(obj => obj.crop === currentObj.crop)
      if (!isDuplicate) { unique.push(currentObj) }
      return unique;
    }, []);
    setUniqueCropList(uniqueObjects);
    setSelectedCropItem(uniqueObjects)
    onCallChartsApis()
  }, [cropsList])

  useEffect(() => {
    if (selectedCropItem?.length) {
      const all_verites_crops = cropsList?.length && cropsList.filter(item =>
        selectedCropItem.some(selectedItem => selectedItem.crop === item.crop)
      )

      const output_list = all_verites_crops.reduce((accumulator, crop) => {
        const existingCrop = accumulator.find((item) => item.title.toLowerCase() === crop.crop.toLowerCase());
        if (existingCrop) {
          existingCrop.data.push(crop);
        } else {
          accumulator.push({
            title: crop.crop,
            data: [crop],
          });
        }
        return accumulator;
      }, []);

      setVarietyList(output_list);
      setSelectedVeriety(output_list?.flatMap(item => item.data))
    } else {
      setVarietyList([]);
    }
  }, [selectedCropItem])

  useEffect(() => {
    if (customDateRange && customDateRange.length === 2) {
      // console.log(customDateRange[0].format(), customDateRange[1].format())
      const startDate = new Date(customDateRange[0].format());
      const endDate = new Date(customDateRange[1].format());
      // Get the UTC time
      const utcStartTime = startDate.getTime();
      const utcEndTime = endDate.getTime();

      startDate.setDate(startDate.getDate());
      startDate.setHours(0, 0, 0, 0);
      endDate.setDate(endDate.getDate());
      endDate.setHours(23, 59, 59, 999);
      // Convert the UTC time back to the desired format "2023-07-01T00:00:00.000Z"
      //   const formattedStartDate = new Date(utcStartTime).toISOString();
      //   const formattedEndDate = new Date(utcEndTime).toISOString();
      //   console.log(formattedStartDate, formattedEndDate);

      setDateRange({ startDate: startDate, endDate: endDate });
    }
  }, [customDateRange])

  const onCallChartsApis = async () => {
    if (cropsList && machineList && dateRange?.startDate && dateRange?.endDate) {
      await onHandleGetChartData(
        cropsList?.map(crop => crop.id),
        machineList?.map(machine => machine.machine_code)
      )
    }
  }

  const onSetDefaultDateRange = () => {
    const today = new Date();
    const currentDay = today.getDay();
    const start_date = new Date(today);
    start_date.setDate(today.getDate() - currentDay);
    setDateRange({ startDate: start_date, endDate: today });
  }

  const HeatMapSeriesData = (data) => {
    const series = [];
    const processData = (cropName, machine, value) => {
      const existingSeries = series.find((item) => item.name === cropName);
      const index = existingSeries ? series.indexOf(existingSeries) : -1;

      if (index > -1) {
        existingSeries.data.push({ x: machine, y: value ? value : 0 });
      } else {
        series.push({
          name: cropName,
          data: [{ x: machine, y: value ? value : 0 }],
        });
      }
    };

    data && data.forEach((item) => {
      const cropName = item[0];
      const machine = item[1];
      const value = item[2];
      processData(cropName, machine, value);
    });

    const sortData = (data) => {
      return data.sort((a, b) => {
        if (a.x < b.x) return -1;
        if (a.x > b.x) return 1;
        return 0;
      });
    };

    const sortList = (list) => {
      return list.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }).map((item) => {
        return { name: item.name, data: sortData(item.data) };
      });
    };

    const sortedList = sortList(series);
    if (sortedList.length > 0) {
      setHeatMapSeriesList(sortedList);
      if (!home_redux.is_loaded_data_first_time) {
        dispatch({
          type: 'HOME_HEATMAP_DATA',
          payload: sortedList
        })
      }
    }
  }

  const CropVsScansData = (dataList) => {
    const gradeRanges = [
      { name: "Grade A", minScore: 90, maxScore: 100 },
      { name: "Grade B", minScore: 80, maxScore: 90 },
      { name: "Grade C", minScore: -1, maxScore: 80 },
    ];

    // Group crops by their names
    const cropGroups = dataList && dataList.reduce((acc, item) => {
      const { crop, overall_score } = item;
      const existingGroup = acc.find((group) => group.name === crop);

      if (existingGroup) {
        existingGroup.data.push(overall_score);
      } else {
        acc.push({ name: crop, data: [overall_score] });
      }

      return acc;
    }, []);

    // Calculate the grade counts for each crop category
    const gradeCounts = cropGroups.map((group) => {
      const { data } = group;
      const gradeData = gradeRanges.map((grade) => {
        const count = data.filter((score) => score > grade.minScore && score <= grade.maxScore).length;
        return count;
      });

      return { name: group.name, data: gradeData };
    });

    const finalList = [];
    for (let i = 0; i < gradeRanges.length; i++) {
      const gradeData = gradeCounts.map(item => (item.data[i]))
      finalList.push({ name: `Grade ${String.fromCharCode(65 + i)}`, data: gradeData });
    }
    const group_name = cropGroups.map((group) => group.name)
    setCropCategoriesStack(group_name)
    if (finalList.length > 0) {
      setSerialStackBarData(finalList)
      if (!home_redux.is_loaded_data_first_time) {
        dispatch({
          type: 'HOME_CROP_VS_SCAN_DATA',
          payload: [group_name, finalList, dateRange]
        })
      }
    } else if (finalList.length === 0) {
      setSerialStackBarData([])
    }
    setHeatMapLoading(false)
    setCropVsScansLoading(false)
  };

  const handleOptionDateChange = (event) => {
    const selectedOption = event.target.value;

    if (selectedOption === 'custom') {
      setIsCustomDateSelected(true);
    } else {
      setIsCustomDateSelected(false);
      const formated_date_range = onGetFormatedDateRange(selectedOption);
      setDateRange({ startDate: formated_date_range.start_date, endDate: formated_date_range.end_date });
    }
    setSelectedDateOption(selectedOption);
  };

  const onHandleGetChartData = async (crop_ids, machine_ids) => {
    try {
      setHeatMapLoading(true)
      setCropVsScansLoading(true)
      const requestData = {
        company_id: localStorage.getItem('company_id'),
        crop_id: crop_ids,
        machine_code: machine_ids,
        start_date: dateRange?.startDate,
        end_date: dateRange?.endDate
      }

      const [heatmapResponce, scansDataUsingCropResponce] = await Promise.all([
        getHeatmapData(requestData),
        getScansDataUsingCrops(requestData)
      ])

      if (heatmapResponce?.data?.data && heatmapResponce.data.data.length > 0) {
        HeatMapSeriesData(heatmapResponce.data.data);
      }
      setMetaData(scansDataUsingCropResponce?.data?.data)
      if (!home_redux.is_loaded_data_first_time) {
        dispatch({
          type: 'HOME_MAP_DATA',
          payload: scansDataUsingCropResponce?.data?.data
        })
      }
      CropVsScansData(scansDataUsingCropResponce?.data?.data)
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      {loading ?
        <div>
          <Box sx={{ p: 1 }}>

            <Box
              className='d-flex flex-direction-custom'
              sx={{ py: 1, gap: 2 }}
            >
              <Card className='border mq-chip-width' style={{ gap: 7, borderRadius: '7px', boxShadow: 0, }}>
                <Box className='d-flex justify-start align-center p-5'>
                  <Box> <PeopleAlt className='APP-COLOR-2' style={{ height: 50, width: 50 }} /></Box>
                  <Box className='p-l-10'>
                    <p className='gray fw-500'>Number Of Users</p>
                    <p className='fs-30 fw-bold align-start'>{numberOfusers ? numberOfusers : ''}</p>
                  </Box>
                </Box>
              </Card>

              <Card className='border mq-chip-width' style={{ gap: 7, borderRadius: '7px', boxShadow: 0, }}>
                <Box className='d-flex justify-start align-center p-5'>
                  <Box> <AiOutlineScan className='APP-COLOR-2' size={50} /></Box>
                  <Box className='p-l-10'>
                    <p className='gray fw-500'>Number Of Scans</p>
                    <p className='fs-30 fw-bold align-start'>{totalScansCount ? totalScansCount : ''}</p>
                  </Box>
                </Box>
              </Card>

              <Card className='border mq-chip-width' style={{ gap: 7, borderRadius: '7px', boxShadow: 0, }}>
                <Box className='d-flex justify-start align-center p-5'>
                  <Box> <LocationOn className='APP-COLOR-2' style={{ height: 50, width: 50 }} /></Box>
                  <Box className='p-l-10'>
                    <p className='gray fw-500'>Total Location</p>
                    <p className='fs-30 fw-bold align-start'>24</p>
                  </Box>
                </Box>
              </Card>
            </Box>

            <Box>
              <Card sx={{ boxShadow: 0, borderRadius: '8px', background: 'transparent' }}>
                <Box className='flex-direction-custom' sx={{ p: 0, display: 'flex', justifyContent: 'space-between', }}>
                  <Box className='d-flex justify-start flex-direction-custom'>
                    <Box className='mq-dropdown-minwidth' sx={{ mr: 1, mt: 1 }}>
                      <AdvanceDropDown
                        title={"Crop"}
                        required={true}
                        data_list={unique_crop_list}
                        unique_key={'id'}
                        show_options_item={(option) => `${option.crop}`}
                        show_name_on_drop_down_box={(option) => `${option.crop}`}
                        get_selected_data={setSelectedCropItem}
                        filter_items={['crop']}
                        height={45}
                        selected_data={selectedCropItem}
                        drop_down_background={'white'} 
                      />
                    </Box>

                    <Box className='mq-dropdown-minwidth' sx={{ mr: 1, mt: 1 }}>
                      <AdvancedSubGropuDropDown
                        title='Variety'
                        required={true}
                        data_list={variety_list}
                        unique_key={'id'}
                        show_options_item={(option) => `${option.variety}`}
                        show_name_on_drop_down_box={(option) => `${option.variety}`}
                        filter_items={['variety']}
                        get_selected_data={setSelectedVeriety}
                        height={45}
                        selected_data={selected_veriety}
                        drop_down_background={'white'}
                      />
                    </Box>

                    <Box className='mq-dropdown-minwidth' sx={{ mr: 1, mt: 1 }}>
                      <AdvanceDropDown
                        title={"Machine"}
                        required={true}
                        data_list={machineList}
                        unique_key={'machine_code'}
                        show_options_item={(option) => `${option.machine_name} - ${option.machine_code}`}
                        show_name_on_drop_down_box={(option) => `${option.machine_name}`}
                        get_selected_data={setSelectedMachine}
                        filter_items={['machine_name', 'machine_code']}
                        height={45}
                        selected_data={selectedMachine}
                        drop_down_background={'white'}
                      />
                    </Box>
                    <Box>
                      <FormControl className='mq-dropdown-minwidth' sx={{ mt: 1 }}>
                        <Select
                          value={selectedDateOption}
                          style={{ height: 45, padding: 0, background: 'white' }}
                          onChange={handleOptionDateChange}
                          displayEmpty
                        >
                          <MenuItem value="all">All</MenuItem>
                          <MenuItem value="today">Today</MenuItem>
                          <MenuItem value='this_week' selected>This Week</MenuItem>
                          <MenuItem value='this_month'>This Month</MenuItem>
                          <MenuItem value='this_year'>This Year</MenuItem>
                          <MenuItem value='custom'>Choose a date</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ ml: 1 }}>{isCustomDateSelected && <DateRangePicker setDateRange={setCustomDateRange} />}</Box>
                  </Box>
                  <div className='d-flex justify-center align-center'>
                    <button variant="contained" className='APP-BACKGROUND border-none border-app-color-2 white cursor m-t-15 b-r-10 rs-width-100' style={{ padding: '10px 20px', height: 42 }}
                      onClick={() => onHandleGetChartData(selected_veriety.length > 0 && selected_veriety?.map(crop => crop.id), selectedMachine.length > 0 && selectedMachine?.map(machine => machine.machine_code))}><b>View Data</b></button>
                  </div>
                </Box>
              </Card>
            </Box>

            <Box className='flex-direction-custom' sx={{ mt: 2, p: 0, display: 'flex', justifyContent: 'space-around', gap: 2 }}>
              {/* Stacked bar graph */}
              <Card className='card-Width m-top-20 b-r-16' sx={{ boxShadow: 0 }}>
                {crop_vs_scans_loading
                  ? <div className=''><CircularProgress sx={{ color: '#b34d00', margin: 2 }} size={25} /></div>
                  : <div>{serialStackBarData && serialStackBarData.length > 0 && <StackedBarChart title={'Crop Vs Scans'} seriesData={serialStackBarData} categoriesData={cropCategoriesStack} yaxis_title='Number Of Scans' xaxis_title='Crops' />}</div>}
              </Card>

              {/* Heatmap heat_map_loading */}
              <Card className='card-Width m-top-20 b-r-16' sx={{ boxShadow: 0}}>
                {heat_map_loading
                  ? <div className=''><CircularProgress sx={{ color: '#b34d00', margin: 2 }} size={25} /></div>
                  : <div>{heatmapSeriesList && heatmapSeriesList?.length > 0 && <HeatMapChart series={heatmapSeriesList} title={'Crop Vs Machine'} yaxiz={'List Of Crops'} />}</div>}
              </Card>
            </Box>

            {!heat_map_loading ? <Box sx={{ p: 0 }}>
              <Box className='flex-direction-custom' sx={{ p: 0, display: 'flex', justifyContent: 'space-around', gap: 2 }}>
                {/* Grade Vs Date */}
                <div className='card-Width b-r-16' sx={{ p: 0.5, boxShadow: 0, border: '1px solid lightgray' }}>
                  {metaData && metaData.length > 0 && <div><DateWithGradeChart data_list={metaData} total_days_range={dateRange} selectedDateOption={selectedDateOption}/></div>}
                </div>
                {/* Quantity Vs Date */}
                <div className='card-Width b-r-16' sx={{ p: 0.5, boxShadow: 0, border: '1px solid lightgray' }}>
                  {metaData && metaData.length > 0 && <div><QuantityVerseDateGraph data_list={metaData} total_days_range={dateRange} selectedDateOption={selectedDateOption}/></div>}
                </div>
              </Box>
            </Box> : <div></div>}

            {/* Map */}
            {!heat_map_loading && metaData && metaData.length > 0 && <div className='b-r-10 m-t-20' style={{ background: 'white' }}>
              <Box sx={{ p: 2, borderRadius: '18px' }}>
                <h3>Scans vs Locations</h3><br></br>
                <MapComponent dataArray={metaData} />
              </Box>
            </div>}

          </Box>
        </div >
        : <LoadingHomePage />
      }
    </div >
  )
}

export default Home