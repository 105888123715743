import React, { useState, useEffect } from 'react';
import { Checkbox, Input, InputAdornment, Menu, MenuItem, Fade, Avatar } from '@mui/material'

export default function AdvanceDropDown({
    title, width, height, required = false, reset_drop_down = false, badge_count = false,
    data_list, get_selected_data, unique_key, show_options_item, show_name_on_drop_down_box, filter_items,
    drop_down_variant, drop_down_background, input_placeholder, input_background, option_background, selected_data
}) {
    const [menu_anchor, setMenuAnchor] = useState(null);
    const open = Boolean(menu_anchor);
    const [updated_list, setUpdatedList] = useState(null)
    const [selected_list, setSelectedList] = useState([])
    const [is_selected_all, setIsSelectedAll] = useState(false)
    useEffect(() => {
        setUpdatedList(data_list)
        if (reset_drop_down === true) {
            setSelectedList([])
            get_selected_data([])
        }
    }, [data_list, reset_drop_down])

    useEffect(() => {
        if (selected_data) {
            setSelectedList(selected_data)
        }
    }, [selected_data])

    const handleClick = (event) => {
        setUpdatedList(data_list);
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
    };

    const onHandleInput = (input) => {
        const input_value = input.target.value.toLowerCase();

        const updated_data = updated_list && data_list && data_list.filter((element) => (
            filter_items && filter_items?.some((key) => {
                const item = element[key];
                return item && item.toLowerCase().includes(input_value);
            })
        ))

        setUpdatedList(updated_data);

        if (!input_value) {
            setUpdatedList(data_list);
        }
    };

    const onHandleCheck = (checked_value) => {
        if (checked_value === 'select_all') {
            if (is_selected_all) {
                data_list && setSelectedList(data_list)
                data_list && get_selected_data(data_list)
                setIsSelectedAll(false)
            } else {
                setSelectedList([])
                get_selected_data([])
                setIsSelectedAll(true)
            }
        } else {
            if (selected_list?.some(element => element[unique_key] === checked_value[unique_key])) {
                const updatedList = selected_list.filter(element => element[unique_key] !== checked_value[unique_key]);
                setSelectedList(updatedList);
                get_selected_data(updatedList)
            } else {
                setSelectedList([...selected_list, checked_value]);
                get_selected_data([...selected_list, checked_value])
            }
        }
        handleClose()
    }

    const onHandleClear = () => {
        setSelectedList([])
        handleClose()
    }

    return (
        <div>
            <Input
                onClick={handleClick}
                value={(selected_list?.length > 0) ? selected_list?.map(show_name_on_drop_down_box) : ''}
                style={{ width: width, height: height, background: drop_down_background, padding: '7px 4px', border: '1px solid grey', borderRadius: 5, color: 'black' }}
                placeholder={required ? `${title}*` : title}
                variant={drop_down_variant}
                readOnly
                endAdornment={
                    <>
                        {(selected_list?.length > 0) && badge_count && <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                            <Avatar style={{ width: 20, height: 20, fontSize: 12, padding: 1 }}>{selected_list?.length}</Avatar>
                        </InputAdornment>}

                        {(selected_list?.length > 0) && <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={onHandleClear}>
                            <Avatar style={{ width: 20, height: 20, fontSize: 12, padding: 1 }}>&#10006;</Avatar>
                        </InputAdornment>}
                    </>
                }
                required={required}
            />
            <Menu
                anchorEl={menu_anchor}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <div>
                    <Input
                        style={{ width: width, padding: 5, background: input_background }}
                        type='text'
                        placeholder={input_placeholder ? input_placeholder : 'Type here...'}
                        onChange={onHandleInput}
                    />
                </div>
                <div style={{ maxHeight: updated_list ? '250px' : '42px', overflowY: 'auto', background: option_background, color: option_background ? 'white' : '' }}>
                    <MenuItem
                        style={{
                            textAlign: 'left',
                            padding: 0,
                            fontSize: '14px',
                            fontFamily: 'cursive'
                        }}>
                        <Checkbox
                            disabled={!updated_list?.length}
                            checked={data_list?.length && data_list?.length === selected_list?.length}
                            onChange={() => onHandleCheck('select_all')}
                        />
                        <i><b>Select All</b></i></MenuItem>
                    {updated_list && updated_list?.map((option, index) => (
                        <MenuItem key={index}
                            style={{
                                fontSize: '14px',
                                margin: 0,
                                textAlign: 'left',
                                width: width,
                                overflow: 'hidden',
                                whiteSpace: 'wrap',
                                padding: '5px 0px',
                                background: selected_list?.some(element => element[unique_key] === option[unique_key]) ? 'rgba(177, 209, 243, 0.664)' : '',
                            }}
                        >
                            <Checkbox
                                checked={selected_list?.some(element => (element[unique_key].includes(option[unique_key])))}
                                onChange={() => onHandleCheck(option)}
                            />
                            {show_options_item && show_options_item(option)}</MenuItem>
                    ))}
                </div>
            </Menu>
        </div>
    )
}