import { CircularProgress } from '@mui/material'
import React from 'react'
import Chart from 'react-apexcharts'

function HeatMapChart({ series, title, yaxiz }) {
  const options = {
    chart: {
      height: 350,
      type: 'heatmap',
    },
    dataLabels: {
      enabled: true,
      colors: '#b34d00'
    },
    plotOptions: {
      heatmap: {
        radius: 5,
        colorScale: {
          ranges: [{
              from: 0,
              to: 50,
              color: '#c46d2b',
              name: 'Low',
            },
            {
              from: 50,
              to: 100,
              color: '#c46d2b',
              name: 'Medium',
            },
            {
              from: 100,
              to: 200,
              color: '#b34d00',
              name: 'High',
            }
          ],
        },
          distributed: true
      }
    },
    colors: ['#b34d00'],
    title: {
      text: title,
      align: 'left',
      style: {
        color: 'gray'
      }
    },
    yaxis: {
      title: {
        text: yaxiz,
        margin: 10,
        style: {
          fontSize: '14px',
          fontWeight: '700',
          // fontFamily: 'bold',
          color: 'gray',
        },
      }
    },
  }
  return (
    <Chart options={options} series={series} type="heatmap" height={320} />
  )
}

export default HeatMapChart
