import React from 'react'
import { Box, Skeleton } from '@mui/material'

function LoadingHomePage() {
  return (
    <Box sx={{ p: 2 }}>

      <div style={{ backgroundColor: 'white', borderRadius: '18px' }}>

        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }} className='flex-direction-custom'>

          <div style={{ padding: '0px 5px', gap: 7, borderRadius: '7px', border: '1px solid lightgray' }} className='card-Width'>
            <div className='d-flex justify-start align-center'>
              <Skeleton height={100} width={70} />
              <div className='p-l-10'>
                <Skeleton height={30} width={200} />
                <Skeleton height={30} width={200} />
              </div>
            </div>
          </div>

          <div style={{ padding: '0px 5px', gap: 7, borderRadius: '7px', border: '1px solid lightgray' }} className='card-Width'>
            <div className='d-flex justify-start align-center'>
              <Skeleton height={100} width={70} />
              <div className='p-l-10'>
                <Skeleton height={30} width={200} />
                <Skeleton height={30} width={200} />
              </div>
            </div>
          </div>

          <div style={{ padding: '0px 5px', gap: 7, borderRadius: '7px', border: '1px solid lightgray' }} className='card-Width'>
            <div className='d-flex justify-start align-center'>
              <Skeleton height={100} width={70} />
              <div className='p-l-10'>
                <Skeleton height={30} width={200} />
                <Skeleton height={30} width={200} />
              </div>
            </div>
          </div>

        </div>

        <div style={{ borderBottomLeftRadius: '18px', borderBottomRightRadius: '18px', margin: 5 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }} className='flex-direction-custom'>
            <Skeleton sx={{ borderRadius: '10px', m: 1 }} animation="wave" variant="rectangular" className='card-Width' height={50} />
            <Skeleton sx={{ borderRadius: '10px', m: 1 }} animation="wave" variant="rectangular" className='card-Width' height={50} />
            <Skeleton sx={{ borderRadius: '10px', m: 1 }} animation="wave" variant="rectangular" className='card-Width' height={50} />
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10 }} className='flex-direction-custom'>
        <Skeleton sx={{ borderRadius: '18px', mt: 2 }} animation="wave" variant="rectangular" className='card-Width' height={350} />
        <Skeleton sx={{ borderRadius: '18px', mt: 2 }} animation="wave" variant="rectangular" className='card-Width' height={350} />
      </div>

    </Box>
  )
}

export default LoadingHomePage