import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Phone } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';

const UserCard = ({ item }) => {
  const navigate = useNavigate()

  const handleUserdetail = (item) => {
    localStorage.setItem('user_id', item.firebase_user_id)
    navigate(`/dashboard/users/${item.firebase_user_id}`);
  }

  return (
    <Card
      sx={{
        minWidth: 100, flexBasis: '30%', margin: '10px', cursor: 'pointer',
        backgroundColor: '#efffe3',
        borderRadius: '12px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
      onClick={() => handleUserdetail(item)}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        </Typography>
        <Typography variant="h5" component="div" sx={{ mb: 1.5, display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ width: 35, height: 35, marginRight: '10px', backgroundColor: 'green', fontSize: '16px' }}>
            {`${item.first_name.charAt(0)}${item.last_name.charAt(0)}`}
          </Avatar>
          {item.first_name} {item.last_name}
        </Typography>
        <Typography sx={{ mb: 1.5, maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} color="text.secondary" style={{ display: 'flex', alignItems: 'center' }}>
          {item.email ? (
            <>
              <EmailOutlinedIcon fontSize="small" style={{ marginRight: '10px', marginLeft: '11px' }} />
              {item.email}
            </>
          ) : (
            <>
              <Phone fontSize="small" style={{ marginRight: '10px', marginLeft: '11px' }} />
              {item.phone_number}
            </>
          )}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{ display: 'flex', alignItems: 'center' }}>
          <DocumentScannerOutlinedIcon fontSize="small" style={{ marginRight: '10px', marginLeft: '11px' }} /> {item.total_scans}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default UserCard;
