import React from 'react'
import DatePicker from 'react-multi-date-picker';
import transition from "react-element-popper/animations/transition"
import InputIcon from "react-multi-date-picker/components/input_icon"
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Toolbar from "react-multi-date-picker/plugins/toolbar"


function MyPlugin({ DatePicker }) {
    return (<div>
        <div className='d-flex justify-between'>
            <Button 
            variant="outlined" 
            sx={{ margin: 1 }} 
            startIcon={< DeleteIcon />}
            // onClick={() => DatePicker.clearSelection()}
            >
                Deselect
            </Button>
            <Button
                sx={{ margin: 1 }}
                variant="outlined"
                startIcon={<SendIcon />}
                onClick={() => DatePicker.closeCalendar()}
            >
                Close
            </Button>
        </div>
    </div>)
}

function DateRangePicker({ setDateRange }) {
    return (
        <div>
            <DatePicker
                // render={<InputIcon onClick={() => setDateRange([])}/>}
                // animations={[
                //     transition({ duration: 800, from: 35 })
                // ]}
                range
                rangeHover
                onOpen={() => true}
                placeholder=" Start Date ~ End Date "
                style={{height: 45, padding: "20px 6px", marginTop: 8, background: 'transparent' }}
                onChange={setDateRange}
                maxDate={new Date()}
                plugins={[
                    <Toolbar position="bottom" />
                    // <MyPlugin position="bottom"  />
                ]}

            />
        </div>
    )
}

export default DateRangePicker