import React, { useEffect, useMemo, useState } from 'react';
import { getAllScanPdf, getScanFiles, getScanPdf, getUserCSV, getCompanyMetaData, getDefaultMetadata } from '../../utils/api';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import { FaImage } from 'react-icons/fa';
import { BsFileEarmarkPdfFill } from 'react-icons/bs';
import MessageModel from '../../widget/MessageModel';
import { Box, Button, Card, Checkbox, CircularProgress, TablePagination, TextField } from '@mui/material';
import CircularLoadingPopUp from '../../widget/CircularLoadingPopUp';
import ExportCsvPopUp from './ExportCsvPopUp';
import AppMessageModel from '../../widget/AppMessageModel';
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function ExpendedTableView({ dataList }) {
  const [modifiedData, setModifiedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [modelType, setModelType] = useState('');
  const [pdfLink, setPdfLink] = useState('');
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTermData] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isExportCsv, setIsExportCsv] = useState(false)
  const [metadataKeys, setMetadataKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMetadataColumns, setShowMetadataColumns] = useState(false);
  const [message, setMessage] = useState('')
  const [successfulMessage, setSuccessfulMessage] = useState(false);
  const [tooltip_content, setTooltipContent] = useState([])
  const [metadata_columns, setMetadataColumns] = useState([]);
  const [tableColumns, setTableColumns] = useState([
    'Scan ID', 'Sub Scans', 'Crop', 'Variety', 'Machine', 'Score/Grade', 'Moisture', 'Scan Date', 'Action'
  ]);

  useEffect(() => {
    modifiedDataAccordingToTable(dataList)
  }, [metadataKeys, dataList]);

  const calculateAverageOverallScore = (history) => {
    const totalScore = history.reduce((total, item) => {
      const kernelDetails = item.scan_results?.scan_result?.kernel_details;
      if (kernelDetails && kernelDetails.overall_score) {
        const score = parseFloat(kernelDetails.overall_score);
        if (!isNaN(score)) {
          return total + score;
        }
      }
      return total;
    }, 0);
    const averageScore = (totalScore / history.length).toFixed(2)
    return averageScore;
  };

  const customAndCapatializeClass = (class_array) => {
    const output_data = []
    if (class_array && class_array?.length !== 0) {
      for (const item of class_array) {
        const split_data = item.split(/[-_]/)
        const capitalized_data = split_data.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        output_data.push(capitalized_data.join(' '))
      }
      return output_data
    }
  }

  function createData(item, index) {
    const grade = item.latestScan.scan_results?.scan_result?.kernel_details?.overall_score_grading;
    const score = calculateAverageOverallScore(item.list_subScan);
    const scanId = item.latestScan.scan_id;
    const subScanCount = item.list_subScan.length;
    const crop = item.latestScan.crop_details.crop;
    const _variety = item.latestScan.crop_details.variety;
    const machine = `${item.latestScan.machine_details.machine_name} ${item.latestScan.machine_details.machine_code}`;
    const date = moment(item.latestScan.scan_date).format('DD MMM, YYYY hh:mm A');
    const finalScore = grade ? 'NA' : score;
    const moistureForScanMetadata = item?.latestScan?.scan_results?.scan_metadata?.moisture
    const quality_score_dependencies = customAndCapatializeClass(item?.latestScan?.scan_results?.scan_result?.kernel_details?.quality_score_dependencies)
    const action = {
      scan_id: item.latestScan.scan_id,
      sub_scan_id: item.latestScan.sub_scan_id,
    };
    const row = {
      scanId,
      subScanCount,
      crop,
      _variety,
      machine,
      date,
      finalScore,
      action,
      quality_score_dependencies,
      history: item.list_subScan,
      moistureForScanMetadata,
      index,
    };
    metadataKeys.forEach((metadataKey) => {
      if (item.latestScan?.scan_results?.scan_metadata?.user_metadata && item?.latestScan?.scan_results?.scan_metadata?.user_metadata[metadataKey]) {
        row[metadataKey] = item.latestScan.scan_results.scan_metadata.user_metadata[metadataKey];
      } else {
        row[metadataKey] = '';
      }
    });
    row.open = false; // Add the open state
    return row;
  }
  const showMetadata = () => {
    setLoading(true);
    setStartDate('')
    setEndDate('')

    // Use Promise.all to make parallel requests
    if (!showMetadataColumns) {
      Promise.all([getCompanyMetaData(), getDefaultMetadata()])
        .then(([companyMetaDataResponse, defaultMetadataResponse]) => {
          const companyMetadata = companyMetaDataResponse?.data?.data?.metadata;
          const defaultMetadata = defaultMetadataResponse?.data?.data?.metadata;

          let newColumns = extractMetadataValues(companyMetadata).concat(
            extractMetadataValues(defaultMetadata)
          );
          newColumns = newColumns.map(item => {
            if (item === 'Moisture') {
              return 'Moisture metadata';
            }
            return item;
          });
          const metadataKeys = extractMetadataKeys(companyMetadata).concat(
            extractMetadataKeys(defaultMetadata)
          );
          if (newColumns.length > 0) {
            setMetadataColumns(newColumns)
            setTableColumns([...tableColumns, ...newColumns]);
            setMetadataKeys(metadataKeys);
          } else {
            setSuccessfulMessage(true);
            setMessage('There is No Metadata to Show');
          }
        })
        .catch((error) => {
          console.error('Error fetching metadata: ', error);
        }).finally(() => {
          setLoading(false);
        });
    } else {
      const filteredColumns = tableColumns.filter((col) => !metadata_columns.includes(col));
      filteredColumns.splice(3, 0, "Variety")
      setTableColumns(filteredColumns);
      setMetadataKeys([]);
      setLoading(false);
    }
    setShowMetadataColumns(!showMetadataColumns);
  };

  const extractMetadataValues = (metadata) => {
    return (
      metadata
        ?.filter((item) => item.metadata_value)
        .map((item) => item.metadata_value) || []
    );
  };

  const extractMetadataKeys = (metadata) => {
    return (
      metadata
        ?.filter((item) => item.metadata_value)
        .map((item) => item.metadata_key) || []
    );
  };

  const modifiedDataAccordingToTable = (dataList) => {
    const output = [];
    const groupedData = {};

    dataList.forEach((item) => {
      const { scan_id } = item;
      if (!groupedData[scan_id]) {
        groupedData[scan_id] = [];
      }
      groupedData[scan_id].push(item);
    });

    const keys = Object.keys(groupedData);

    for (let i = 0; i < keys.length; i++) {
      const scan_id = keys[i];

      const latestItem = groupedData[scan_id].reduce((latest, item) => {
        return item.sub_scan_id > latest.sub_scan_id ? item : latest;
      }, { sub_scan_id: -1 });

      output.push({
        latestScan: latestItem,
        scan_id: scan_id,
        list_subScan: groupedData[scan_id],
      });
    }
    const rows = output.map((item, index) => createData(item, index));
    setModifiedData(rows);
    const filtered_rows = rows.filter((row) => {
      const scanId = row.scanId
      const searchTermLower = searchTerm.toLowerCase();
      const matchesExistingFields =
        (scanId && scanId.toLowerCase().includes(searchTermLower)) ||
        (row?.crop && row?.crop.toLowerCase().includes(searchTermLower)) ||
        (row?.machine && row?.machine.toLowerCase().includes(searchTermLower));
      const matchesMetadataKeys = metadataKeys && metadataKeys?.some((key) => {
        return key in row && row[key]?.toLowerCase().includes(searchTermLower);
      });
      return matchesExistingFields || matchesMetadataKeys;
    });

    setFilteredData(filtered_rows);
  }

  useEffect(() => {
    if (startDate && endDate) {
      const newStartDate = moment(startDate, 'YYYY-MM-DD hh:mm A').startOf('day');
      const newEndDate = moment(endDate, 'YYYY-MM-DD hh:mm A').endOf('day');

      const filteredDataList = dataList.filter((item) => {
        const scanDate = moment(item.scan_date, 'YYYY-MM-DD hh:mm A');
        return scanDate.isSameOrAfter(newStartDate) && scanDate.isSameOrBefore(newEndDate);
      });
      modifiedDataAccordingToTable(filteredDataList)
    } else {
      modifiedDataAccordingToTable(dataList)
    }
  }, [startDate, endDate]);

  useMemo(() => {
    let filteredRows = modifiedData;
    if (page !== 0 && searchTerm) {
      setPage(0)
    }
    if (filteredRows.length > 0 && searchTerm) {
      filteredRows = filteredRows.filter((row) => {
        const scanId = row.scanId
        const searchTermLower = searchTerm.toLowerCase();
        const matchesExistingFields =
          (scanId && scanId.toLowerCase().includes(searchTermLower)) ||
          (row?.crop && row?.crop.toLowerCase().includes(searchTermLower)) ||
          (row?.machine && row?.machine.toLowerCase().includes(searchTermLower));
        const matchesMetadataKeys = metadataKeys && metadataKeys?.some((key) => {
          return key in row && row[key]?.toLowerCase().includes(searchTermLower);
        });
        return matchesExistingFields || matchesMetadataKeys;
      });
    }
    setFilteredData(filteredRows);
  }, [searchTerm, metadataKeys]);


  const actionOnImage = async (action) => {
    try {
      setIsOpenModel(true);
      setModelType('image');
      const res = await getScanFiles(action.scan_id, action.sub_scan_id);
      const scansFilesList = res?.data?.data?.scan_files && res?.data?.data?.scan_files.map((files) => ({
        image: files.file_url,
        file_name: files.file_name,
      }));
      setPdfLink(scansFilesList);
    } catch (error) {
      console.error(error);
    }
  };

  const actionOnPdf = async (action) => {
    try {
      // setIsOpenModel(true);
      // setModelType('pdf');
      setLoadingPDF(true)
      const res = await getScanPdf(action.scan_id);
      const downloadUrl = URL.createObjectURL(res.data);
      window.open(downloadUrl)
      // setPdfLink(downloadUrl);
    } catch (error) {
      console.error(error);
      setSuccessfulMessage(true);
      setMessage('Unable to download pdf. Something went wrong');
    } finally {
      setLoadingPDF(false)
    }
  };
  const actionOnPdfInSubScans = async (action) => {
    try {
      // setIsOpenModel(true);
      // setModelType('pdf');
      setLoadingPDF(true)
      const res = await getScanPdf(action.scan_id, action.sub_scan_id);
      const downloadUrl = URL.createObjectURL(res.data);
      window.open(downloadUrl)
      // setPdfLink(downloadUrl);
    } catch (error) {
      console.error(error);
      setSuccessfulMessage(true);
      setMessage('Unable to download pdf. Something went wrong');
    } finally {
      setLoadingPDF(false)   
    }
  };

  const onHandleSelectAllRow = (event) => {
    if (event.target.checked) {
      console.log(filteredData);
      setSelectedRowData(
        filteredData &&
        filteredData.map((user) => ({
          id: user.index,
          scan_id: user.action.scan_id,
          sub_scan_id: user.action.sub_scan_id,
        }))
      );
    } else {
      setSelectedRowData([]);
    }
  };

  const onHandleSelectEachRow = (event, row) => {
    if (event.target.checked) {
      const scans = {
        id: row.index,
        scan_id: row.action.scan_id,
        sub_scan_id: row.action.sub_scan_id,
      };
      setSelectedRowData([...selectedRowData, scans]);
    } else {
      setSelectedRowData((prevSelectedData) =>
        prevSelectedData.filter(
          (selectedRow) =>
            !(selectedRow.scan_id === row.action.scan_id && selectedRow.sub_scan_id === row.action.sub_scan_id)
        )
      );
    }
  };

  const onDownloadCsv = async () => {
    setLoadingCSV(true);
    try {
      const path = window.location.pathname;
      const firebaseUserId = path.split('/').pop();
      if (selectedRowData.length > 50) {
        alert("Please select 50 items or fewer for CSV download.");
      } else {
        const scanlist = selectedRowData.map((item) => {
          const { id, ...rest } = item;
          return rest;
        });
        await getUserCSV(scanlist, firebaseUserId);
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingCSV(false);
  };

  const onDownloadScanPDF = async () => {
    setLoadingCSV(true);
    try {
      const scanlist = selectedRowData.map((item) => {
        const { id, ...rest } = item;
        return {
          ...rest,
          pdf_type: 'LONG',
        };
      });
      await getAllScanPdf(scanlist);
    } catch (error) {
      console.error(error);
    }
    setLoadingCSV(false);
  };

  const onHandleExpandeView = (rowIndex) => {
    setModifiedData((prevData) => {
      const updatedData = [...prevData];
      updatedData[rowIndex].open = !updatedData[rowIndex].open;
      return updatedData;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleCropList = filteredData && filteredData.slice(startIndex, endIndex);
  const buttonText = showMetadataColumns ? "Hide Metadata" : "Show Metadata";

  return (
    <div className='m-t-10'>
      {isExportCsv && <ExportCsvPopUp
        open={isExportCsv}
        setOpen={setIsExportCsv}
        allScansList={dataList}
        selectedRowData={selectedRowData}
      />}
      <Card sx={{ p: 2, boxShadow: 0, borderRadius: '18px' }}>
        <Box sx={{ marginBottom: 2 }}>
          <div className='d-flex justify-between m-t-20 g-5 flex-direction-custom'>
            <div className='d-flex align-center g-5 m-b-10'>
              <TextField
                className='w-100 rs-width-100'
                label='Search'
                type='text'
                style={{ height: '32px', marginBottom: 15 }}
                onChange={(e) => setSearchTermData(e.target.value)}
              />
            </div>
            <div className='d-flex align-center g-5 flex-direction-custom'>
              <Box style={{ display: 'flex', flexDirection: 'column' }} className='rs-width-100'>
                <label style={{ textAlign: 'start' }}><b>Start Date</b></label>
                <input label='date' type='date' className='p-8 b-5 border-darkblue' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'column' }} className='rs-width-100'>
                <label style={{ textAlign: 'start' }}><b>End Date</b></label>
                <input type='date' className='p-8 b-5 border-darkblue' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </Box>
              {selectedRowData.length > 0 && (
                <Box className='rs-width-100'>
                  {loadingCSV ? <CircularLoadingPopUp /> : (
                    <Button variant='outlined' className='bg-aliceblue dark-blue m-l -10 m-t-20 rs-width-100' onClick={onDownloadCsv}><strong>
                      Export CSV
                    </strong></Button>
                  )}
                </Box>
              )}

              {selectedRowData.length > 0 && <Box className='rs-width-100'>
                <Button variant='outlined' className='bg-aliceblue dark-blue m-l -10 m-t-20 rs-width-100' onClick={() => setIsExportCsv(true)}><strong>
                  Crop Wise Csv
                </strong></Button>
              </Box>}
              {
                loading || loadingPDF ? <CircularLoadingPopUp /> : ('')
              }
              <AppMessageModel
                open={successfulMessage}
                setOpen={setSuccessfulMessage}
                message={message}
              />
              {selectedRowData.length > 0 && (
                <Box className='rs-width-100'>
                  {loadingCSV ? <CircularLoadingPopUp /> : (
                    <Button variant='outlined' className='bg-aliceblue dark-blue m-l -10 m-t-20 rs-width-100' onClick={onDownloadScanPDF}><strong>
                      Download PDF
                    </strong></Button>
                  )}
                </Box>
              )}
              <Button variant='outlined' className='bg-aliceblue dark-blue m-l -10 m-t-20 rs-width-100' onClick={showMetadata}><strong>
                {buttonText}
              </strong></Button>
            </div>
          </div>
        </Box>
        {isOpenModel && <MessageModel open={isOpenModel} onClose={setIsOpenModel} type={modelType} pdfLink={pdfLink} setPdfLink={setPdfLink} />}
        <TableContainer style={{ boxShadow: 0 }} component={Paper}>
          <Table size='small' aria-label='collapsible table'>
            <TableHead>
              <TableRow style={{ backgroundColor: 'aliceblue', height: 7 }}>
                <TableCell className='p-0' />
                <TableCell className='p-0'>
                  <Checkbox
                    checked={selectedRowData.length > 0 && modifiedData.length === selectedRowData.length}
                    onChange={onHandleSelectAllRow}
                    color='primary'
                  />
                </TableCell>
                {tableColumns && tableColumns.map((col, index) => (
                  <TableCell className='p-0' style={{ width: col === 'Moisture' || col === 'Score/Grade' ? '100px' : '' }} key={index}>{col}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(visibleCropList.length === 0)
                ? (
                  <TableRow>
                    <TableCell colSpan={tableColumns.length + 2} style={{ textAlign: 'center' }}>
                      <p className='p-10 fs-18'>No results found.</p>
                    </TableCell>
                  </TableRow>
                )
                : (
                  visibleCropList?.length !== 0 && visibleCropList?.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell className='p-0'>
                          <IconButton
                            disabled={row.subScanCount === 1}
                            aria-label='expand row'
                            size='small'
                            onClick={() => onHandleExpandeView(row.index)}
                          >
                            {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell className='p-0'>
                          <Checkbox
                            checked={selectedRowData.length > 0 && selectedRowData.some((item) => row.index === item.id)}
                            onChange={(e) => onHandleSelectEachRow(e, row)}
                            color='primary'
                          />
                        </TableCell>
                        <TableCell className='p-0'>{row.scanId} </TableCell>
                        <TableCell className='p-0'>{row.subScanCount}</TableCell>
                        <TableCell className='p-0'>{row.crop}</TableCell>
                        <TableCell className='p-0'>{row._variety}</TableCell>
                        <TableCell className='p-0'>{row.machine}</TableCell>
                        <TableCell className='p-0' style={{ width: '3rem' }}><p data-tooltip-id="tooltip" className='cursor' onMouseEnter={() => setTooltipContent(row.quality_score_dependencies)}>{row.finalScore}</p></TableCell>
                        <TableCell className='p-0'>{row.moistureForScanMetadata}</TableCell>
                        <TableCell className='p-0'>{row.date}</TableCell>
                        <TableCell className='p-0'>
                          <FaImage className='APP-COLOR cursor' style={{ fontSize: 20, textAlign: 'center' }} onClick={() => actionOnImage(row.action)} />
                          <BsFileEarmarkPdfFill className='APP-COLOR cursor' style={{ fontSize: 20, textAlign: 'center', marginLeft: 5 }} onClick={() => actionOnPdf(row.action)} />
                        </TableCell>
                        {metadataKeys && metadataKeys.map((key) => (
                          <TableCell className='p-0 metadata-cell' key={key}>
                            {key in row && row[key] !== '' ? row[key] : '-'}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={tableColumns.length + 2}>
                          <Collapse in={row.open} timeout='auto' unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Typography variant='h6' gutterBottom component='div'>
                                Sub Scans
                              </Typography>
                              <Table size='small' aria-label='purchases'>
                                <TableHead style={{ backgroundColor: 'lightgray' }}>
                                  <TableRow>
                                    {tableColumns && tableColumns.map((col, index) => (
                                      <TableCell className='p-0' key={index}>{col}</TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row.history.map((historyRow, index) => (
                                    <TableRow key={index}>
                                      <TableCell component='th' scope='row'>
                                        {historyRow.scan_id}
                                      </TableCell>
                                      <TableCell>{historyRow.sub_scan_id}</TableCell>
                                      <TableCell>{historyRow.crop_details.crop}</TableCell>
                                      <TableCell>{historyRow.crop_details.variety}</TableCell>
                                      <TableCell>{historyRow.machine_details.machine_name} ({historyRow.machine_details.machine_code})</TableCell>
                                      <TableCell>
                                        {historyRow.scan_results.scan_result.kernel_details.overall_score_grading ? 'NA' : historyRow.scan_results?.scan_result?.kernel_details?.overall_score}
                                      </TableCell>
                                      <TableCell>{historyRow.scan_results?.scan_metadata?.moisture}</TableCell>
                                      <TableCell>{moment(historyRow.scan_date).format('DD MMM, YYYY hh:mm A')}</TableCell>
                                      <TableCell>
                                        <FaImage className='APP-COLOR cursor' style={{ fontSize: 20, textAlign: 'center' }} onClick={() => actionOnImage(historyRow)} />
                                        <BsFileEarmarkPdfFill className='APP-COLOR cursor' style={{ fontSize: 20, textAlign: 'center', marginLeft: 5 }} onClick={() => actionOnPdfInSubScans(historyRow)} />
                                      </TableCell>
                                      {metadataKeys && metadataKeys.map((key) => (
                                        <TableCell className='p-0 metadata-cell' key={key}>
                                          {historyRow?.scan_results?.scan_metadata?.user_metadata?.[key] !== undefined && historyRow?.scan_results?.scan_metadata?.user_metadata?.[key] !== '' ? historyRow.scan_results.scan_metadata.user_metadata[key] : '-'}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
      <ReactTooltip
        id="tooltip"
        place="top"
        content={<p>Count %  {tooltip_content && tooltip_content?.map((content, index) => <span key={index}><span style={{ fontSize: '20px', fontWeight: 'bold' }}></span>{content}, </span>)} kernels</p>}
        style={{ backgroundColor: 'aliceblue', color: 'black', border: '1px solid gray' }}
      />
    </div>
  );
}