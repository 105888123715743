import React from 'react'
import UsersApprovalList from './UsersApprovalList'
import PaymentHistroy from './PaymentHistroy'
import Machine from './Machine'
import CompanyCrops from './CompanyCrops'
import AccessPaymentHistroy from './AccessPaymentHistroy'
import CompanyMetaData from './CompanyMetaData'

function Setting() {
  return (
    <div>
      <div style={{ margin: 30 }}>
        <UsersApprovalList />
      </div>
      {localStorage.getItem('user_role') === 'L0' && <div>
        <div style={{ margin: 30 }}><AccessPaymentHistroy /></div>

        <div style={{ margin: 30 }}>
          <PaymentHistroy />
        </div>
      </div>}
      <div style={{ margin: 30 }}>
        <div className='d-flex justify-between flex-direction-custom g-3' >
          <CompanyCrops />
          <Machine />
        </div>
      </div>
      {localStorage.getItem('user_role') === 'L0' && <div style={{ margin: 30 }}>
        <CompanyMetaData />
      </div>}
    </div>
  )
}

export default Setting