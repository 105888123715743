import { Box, Card, FormControl, Grid, MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getUserById } from '../../utils/api'
import { BsArrowLeftCircleFill } from 'react-icons/bs'
import LinearProgress from '@mui/material/LinearProgress';
import DonutChart from '../../charts/DonutChart'
import axios from 'axios'
import StackedBarChart from '../../charts/StackedBarChart'
import { useNavigate } from 'react-router-dom'
import LoadingSkeleton from '../../widget/LoadingSkeleton'
import ExpendedTableView from './ExpendedTableView'
import DateWithGradeChart from './DateWithGradeChart';
import QuantityVerseDateGraph from './QuantityVerseDateGraph';
import DateRangePicker from '../../widget/DateRangePicker';
import { onGetFormatedDateRange } from '../../utils/common';

function UserDetails() {
    const [userScanData, setUserScanData] = useState([])
    const [loading, setLoading] = useState(true);
    const [userLastLoaction, setUserListLoaction] = useState('')
    const [seriesList, setSeriesList] = useState([])
    const [labelsList, setLabelsList] = useState([])
    const [cropCategoriesStack, setCropCategoriesStack] = useState([])
    const [serialStackBarData, setSerialStackBarData] = useState([])
    const [userData, setUserData] = useState('')
    const navigate = useNavigate()
    const minHeight = `calc(${window.innerHeight}px - 52px)`;
    const [filteredData, setFilteredDate] = useState([])
    const [dateRange, setDateRange] = useState('')
    const [selectedDateOption, setSelectedDateOption] = useState('this_week');
    const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);
    const [customDateRange, setCustomDateRange] = useState({ startDate: '', endDate: '' })


    useEffect(() => {
        const today = new Date();
        const currentDay = today.getDay();
        const start_date = new Date(today);
        start_date.setDate(today.getDate() - currentDay);
        setDateRange({ startDate: start_date, endDate: today });
    }, [])

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const path = window.location.pathname;
                const id = path.split("/").pop();
                if (dateRange?.startDate && dateRange?.endDate) {
                    const response = await getUserById(id, dateRange?.startDate, dateRange?.endDate);
                    if (response?.data?.data) {
                        setUserData(response?.data?.data.user)

                        const dataList = response?.data?.data && response?.data?.data.all_scans.map((item, index) => (
                            { ...item, id: index }
                        ))

                        response?.data?.data && setUserScanData(dataList)
                        response?.data?.data && showOnlyUniqueScanIDData(dataList)
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)
            }
        })()
    }, [dateRange])

    useEffect(() => {
        if (customDateRange && customDateRange.length === 2) {
            console.log(customDateRange[0].format(), customDateRange[1].format())
            const startDate = new Date(customDateRange[0].format());
            const endDate = new Date(customDateRange[1].format());
            // Get the UTC time
            const utcStartTime = startDate.getTime();
            const utcEndTime = endDate.getTime();

            startDate.setDate(startDate.getDate());
            startDate.setHours(0, 0, 0, 0);
            endDate.setDate(endDate.getDate());
            endDate.setHours(23, 59, 59, 999);
            // Convert the UTC time back to the desired format "2023-07-01T00:00:00.000Z"
            //   const formattedStartDate = new Date(utcStartTime).toISOString();
            //   const formattedEndDate = new Date(utcEndTime).toISOString();
            //   console.log(formattedStartDate, formattedEndDate);

            setDateRange({ startDate: startDate, endDate: endDate });
        }
    }, [customDateRange])

    const stackDataList = (dataList) => {
        const result = new Map();
        dataList.forEach((item) => {
            const cropName = item.crop_details.crop;
            const machine = `${item.machine_details.machine_name} (${item.machine_details.machine_code})`;

            if (!result.has(cropName)) {
                result.set(cropName, new Map());
            }

            const machineCount = result.get(cropName);
            machineCount.set(machine, (machineCount.get(machine) || 0) + 1);
        });

        // Transforming into the desired output format
        // const output = [...result.entries()].map(([cropName, machineCount]) => ({
        //     [cropName]: Object.fromEntries(machineCount.entries()),
        // }));

        // console.log("output", output);
        // Extracting data into separate arrays
        let totalMachines = 0
        const machineNameList = []
        result.forEach((values, keys) => {
            const arr = Array.from(values, ([key, value]) => {
                !machineNameList.includes(key) && machineNameList.push(key)
                return { [key]: value };
            });
            totalMachines = Math.max(totalMachines, arr.length)
        })

        const dynamicArrays = {};
        for (let i = 0; i < machineNameList.length; i++) {
            const machineName = machineNameList[i];
            dynamicArrays[machineName] = [];
        }

        const crops = [];
        for (const [itemName, item] of result) {
            crops.push(itemName)
            for (let i = 0; i < machineNameList.length; i++) {
                const machineName = machineNameList[i];
                const value = item.has(machineName) ? item.get(machineName) : 0;
                dynamicArrays[machineName].push(value);
            }
        }

        const finalList = [];
        for (let i = 0; i < machineNameList.length; i++) {
            const machineName = machineNameList[i];
            finalList.push({ name: machineName, data: dynamicArrays[machineName] });
        }

        setCropCategoriesStack(crops)
        setSerialStackBarData(finalList)
    }

    const donutDataList = (dataList) => {
        if (dataList) {
            const map = new Map()
            for (let item of dataList) {
                if (map.has(item.crop_details.crop)) {
                    map.set(item.crop_details.crop, map.get(item.crop_details.crop) + 1)
                } else {
                    map.set(item.crop_details.crop, 1)
                }
            }
            const label = []
            const series = []
            for (let item of map.keys()) {
                label.push(item)
                series.push(map.get(item))
            }
            setLabelsList(label)
            setSeriesList(series)
        }
    }

    const fetchUserLastLoaction = async (data) => {
        const latitude = data?.scan_results?.scan_metadata?.latitude
        const longitude = data?.scan_results?.scan_metadata?.longitude
        const response = longitude && latitude && await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
        const location = response?.data?.address?.state_district + ", " + response?.data?.address?.state;
        location && setUserListLoaction(location)
    }

    const showOnlyUniqueScanIDData = async (dataList) => {
        const uniqueScanData = {};
        const uniqueDataList = dataList.filter((item) => {
            if (!uniqueScanData[item.scan_id]) {
                uniqueScanData[item.scan_id] = true;
                return true;
            }
            return false;
        });
        const countData = uniqueDataList.map((item) => {
            const subScanCount = dataList.filter((subItem) => subItem.scan_id === item.scan_id).length;
            return { ...item, sub_scan_count: subScanCount };
        });
        setFilteredDate(countData);
        await fetchUserLastLoaction(dataList[0])
        Promise.all([
            donutDataList(countData),
            stackDataList(countData)
        ]);
    };

    const handleOptionDateChange = (event) => {
        const selectedOption = event.target.value;

        if (selectedOption === 'custom') {
            setIsCustomDateSelected(true);
        } else {
            setIsCustomDateSelected(false);
            const formated_date_range = onGetFormatedDateRange(selectedOption);
            setDateRange({ startDate: formated_date_range.start_date, endDate: formated_date_range.end_date });
        }
        setSelectedDateOption(selectedOption);
    };

    return (
        <div style={{ background: '#f1f1f1', minHeight: minHeight }}>
            {userData ? <Box>
                <Box sx={{ p: 2 }}>
                    <Card sx={{ boxShadow: 0, borderRadius: '18px' }}>
                        {loading && <LinearProgress color='warning' />}
                        <Box sx={{ textAlign: 'left', p: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'start', gap: 1 }}>
                                    <BsArrowLeftCircleFill style={{ fontSize: 18, margin: '11px 10px', }} className="cursor APP-COLOR" onClick={() => navigate('/dashboard/users')} />
                                    <Box>
                                        <Box sx={{ fontSize: 30, fontWeight: 'bold', fontStyle: 'italic' }}>{userData?.first_name} {userData?.last_name}</Box>
                                        <Box sx={{ fontSize: 15, fontWeight: '500' }}>{userData?.email ? userData?.email : userData?.phone_number}</Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'start', gap: 1 }}>
                                    <Box>
                                        <FormControl className='mq-dropdown-minwidth' sx={{ mt: 1 }}>
                                            <Select
                                                value={selectedDateOption}
                                                style={{ height: 45, padding: 0 }}
                                                onChange={handleOptionDateChange}
                                                displayEmpty
                                            >
                                                <MenuItem value="all">All</MenuItem>
                                                <MenuItem value="today">Today</MenuItem>
                                                <MenuItem value='this_week' selected>This Week</MenuItem>
                                                <MenuItem value='this_month'>This Month</MenuItem>
                                                <MenuItem value='this_year'>This Year</MenuItem>
                                                <MenuItem value='custom'>Choose a date</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ ml: 1 }}>{isCustomDateSelected && <DateRangePicker setDateRange={setCustomDateRange} />}</Box>
                                </Box>

                            </Box>
                            {<Box sx={{ mt: 2, p: 1, border: '1px solid lightgray', borderRadius: '10px' }}>
                                <Box>
                                    <Box sx={{ flexGrow: 1, p: 2 }}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p style={{ fontWeight: 500, color: 'gray', fontSize: 'medium' }}>Last Scan Location</p>
                                                    <p style={{ fontWeight: 500, fontSize: 'medium' }}>{userLastLoaction ? userLastLoaction !== 'undefined, undefined' ? userLastLoaction : '-' : '-'}</p>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <Box className='mq-borderLeft' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <p style={{ fontWeight: 500, color: 'gray', fontSize: 'medium', paddingLeft: 5 }}>Total Scans</p>
                                                    <p style={{ fontWeight: 500, fontSize: 'medium' }}>{filteredData.length ? filteredData.length : '-'}</p>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                            }
                        </Box>
                    </Card>
                    {!loading ? <Box>
                        <Box className='flex-direction-custom' sx={{ mt: 2, p: 0, display: 'flex', justifyContent: 'space-around', }}>
                            {seriesList.length ? <Card className='card-Width b-r-16' sx={{ p: 0.5, boxShadow: 0 }}>
                                <DonutChart series={seriesList} labels={labelsList} />
                            </Card> : <div> </div>}
                            <Box sx={{ width: '1%' }}></Box>
                            {serialStackBarData.length ? <Card className='card-Width b-r-16 m-top-20' sx={{ p: 0.5, boxShadow: 0 }}>
                                <StackedBarChart seriesData={serialStackBarData} categoriesData={cropCategoriesStack} title="Crop Vs Machine" yaxis_title='Number Of Scans' xaxis_title='Crops' />
                            </Card> : <div></div>}
                        </Box>

                        {userScanData && userScanData.length > 0 && <Box className='flex-direction-custom' sx={{ display: 'flex', justifyContent: 'space-around', }}>
                            <Box className='card-Width b-r-16' sx={{ boxShadow: 0 }}>
                                <Box><DateWithGradeChart data_list={userScanData} total_days_range={dateRange} selectedDateOption={selectedDateOption}/></Box>
                            </Box>
                            <Box className='card-Width b-r-16' sx={{ p: 0.5, boxShadow: 0 }}>
                                <Box><QuantityVerseDateGraph data_list={userScanData} total_days_range={dateRange} selectedDateOption={selectedDateOption}/></Box>
                            </Box>
                        </Box>}

                        {userScanData && userScanData.length > 0 &&
                            <ExpendedTableView dataList={userScanData} />
                        }
                    </Box> : <div></div>}
                </Box>
            </Box> : <LoadingSkeleton />}
        </div>
    )
}

export default UserDetails