import { createStore, applyMiddleware, combineReducers } from 'redux';
import  {thunk} from 'redux-thunk';
import logger from 'redux-logger';
import homeReducer from './reducers/home_reducer';
import userReducer from './reducers/user_reducer';

const rootReducer = combineReducers({
  home: homeReducer,
  user: userReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;