import { Skeleton } from '@mui/material'
import React from 'react'

function LoadingUserPage() {
    return (
        <div>
            <div>

                <div style={{ width: 300, padding: 5, border: '1px solid lightgray', borderRadius: 10 }}>
                    <div style={{ backgroundColor: 'white', padding: 5, display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 5, }}>
                        <Skeleton sx={{ borderRadius: '50%' }} animation="wave" variant="rectangular" height={50} width={50} />
                        <Skeleton sx={{ borderRadius: '10px' }} animation="wave" variant="rectangular" height={35} width={220} />
                    </div>

                    <div style={{ backgroundColor: 'white', padding: 2, display: 'flex', justifyContent: 'start', gap: 5, }}>
                        <Skeleton sx={{ borderRadius: '50%' }} animation="wave" variant="rectangular" height={30} width={30} />
                        <Skeleton sx={{ borderRadius: '10px' }} animation="wave" variant="rectangular" height={30} width={240} />
                    </div>

                    <div style={{ backgroundColor: 'white', padding: 2, display: 'flex', justifyContent: 'start', gap: 5, }}>
                        <Skeleton sx={{ borderRadius: '50%' }} animation="wave" variant="rectangular" height={30} width={30} />
                        <Skeleton sx={{ borderRadius: '10px' }} animation="wave" variant="rectangular" height={30} width={240} />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default LoadingUserPage