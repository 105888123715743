import { getUserList } from "../utils/api";

export const getUserDataList = () => {
    return async (dispatch) => {
        try {
            const response = await getUserList()
            dispatch({
                type: 'USER_DATA_LIST',
                payload: response?.data?.data?.user_list
            });
        } catch (error) {
            dispatch({
                type: 'FETCH_ERROR',
                payload: error.message
            });
        }
    };
};

export const clearReduxForUserComponent = () => {
    return async dispatch => {
        dispatch({
            type: 'LOGOUT',
        });
    }
}

export const clearReduxDataForUserComponent = () => {
    return async dispatch => {
        dispatch({
            type: 'CLAER_CACHE_REDUX_USER',
        });
    }
}