const initialState = {
    user_count: 0,
    user_scans_count: 0,
    home_heat_map_data: [],
    home_map_data: [],
    home_crop_vs_scans_data: {
        crop_Categories: [],
        serial_data: []
    },
    default_date_range: {},
    crop_list: [],
    machine_list: [],
    loading: false,
    error: null,
    is_loaded_data_first_time: false
};

const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'user_count_success':
            return {
                ...state,
                user_count: action.payload[0],
                user_scans_count: action.payload[1]
            }
        case 'crop_machine_data':
            return {
                ...state,
                crop_list: action.payload[0],
                machine_list: action.payload[1],
            }
        case 'HOME_HEATMAP_DATA':
            return {
                ...state,
                home_heat_map_data: action.payload,
                is_loaded_data_first_time: true
            }
        case 'HOME_MAP_DATA':
            return {
                ...state,
                home_map_data: action.payload
            }
        case 'HOME_CROP_VS_SCAN_DATA':
            return {
                ...state,
                home_crop_vs_scans_data: {
                    crop_Categories: action.payload[0],
                    serial_data: action.payload[1],
                },
                default_date_range: action.payload[2]
            }
        case 'FETCH_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'CLAER_CACHE_REDUX_HOME': {
            return initialState
        }
        case 'LOGOUT': {
            return initialState
        }
        default:
            return state;
    }
}

export default homeReducer;
