import React, { useState, useEffect } from 'react';
import { Checkbox, Input, InputAdornment, Menu, MenuItem, Fade, Avatar } from '@mui/material'

export default function AdvancedSubGropuDropDown({
    title = 'Drop Down', width, height, required, badge_count = false, reset_drop_down = false,
    data_list, get_selected_data, unique_key, show_options_item, show_name_on_drop_down_box, filter_items,
    drop_down_variant, drop_down_background, input_placeholder, input_background, option_background, selected_data
}) {
    const [menu_anchor, setMenuAnchor] = useState(null);
    const open = Boolean(menu_anchor);
    const [updated_list, setUpdatedList] = useState(null)
    const [store_orignal_data_list, setStoreOrignalDataList] = useState(null)
    const [selected_list, setSelectedList] = useState([])
    const [is_selected_all, setIsSelectedAll] = useState(false)

    useEffect(() => {
        setUpdatedList(data_list)
        setStoreOrignalDataList(extractWithFlatterDataList())

        if (reset_drop_down === true) {
            setSelectedList([])
            get_selected_data([])
        }
    }, [data_list, reset_drop_down])

    useEffect(() => {
        if (selected_data) {
            setSelectedList(selected_data)
        }
    }, [selected_data])

    const handleClick = (event) => {
        setUpdatedList(data_list)
        setMenuAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setMenuAnchor(null);
    };

    const onHandleInput = (input) => {
        const input_value = input.target.value.toLowerCase();

        const updated_data = data_list && data_list.map((element) => ({
            ...element,
            data: element.data.filter((item) =>
                filter_items.some((key) => {
                    const value = item[key];
                    return value && value.toString().toLowerCase().includes(input_value);
                })
            ),
        })).filter((element) => element.data.length > 0);

        setUpdatedList(updated_data);

        if (!input_value) {
            setUpdatedList(data_list);
        }
    };



    const extractWithFlatterDataList = () => {
        const all_data_object = data_list?.flatMap(data => data.data) || []
        return all_data_object.flat()
    }

    const onHandleCheck = (checked_value) => {
        if (checked_value === 'select_all') {
            if (is_selected_all) {
                const flatter_data = extractWithFlatterDataList()
                data_list?.length && setSelectedList(flatter_data)
                data_list?.length && get_selected_data(flatter_data)
                setIsSelectedAll(false)
            } else {
                setSelectedList([])
                get_selected_data([])
                setIsSelectedAll(true)
            }
        } else {
            if (selected_list?.some(element => element[unique_key] === checked_value[unique_key])) {
                const updatedList = selected_list.filter(element => element[unique_key] !== checked_value[unique_key]);
                setSelectedList(updatedList);
                get_selected_data(updatedList)
            } else {
                setSelectedList([...selected_list, checked_value]);
                get_selected_data([...selected_list, checked_value])
            }
        }
    }

    const onHandleClear = () => {
        setSelectedList([])
    }

    return (
        <div>
            <Input
                onClick={handleClick}
                value={selected_list?.length > 0 ? selected_list?.map(show_name_on_drop_down_box) : ''}
                style={{ width: width, height: height, background: drop_down_background, padding: '10px 4px', border: '1px solid grey', borderRadius: 5, color: 'black' }}
                placeholder={required ? `${title}*` : title}
                variant={drop_down_variant}
                underline={false}
                readOnly
                endAdornment={
                    <>
                        {badge_count && <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                            <Avatar style={{ width: 20, height: 20, fontSize: 12, padding: 1 }}>{selected_list?.length}</Avatar>
                        </InputAdornment>}

                        {(selected_list?.length > 0) && <InputAdornment position="end" style={{ cursor: 'pointer' }} onClick={onHandleClear}>
                            <Avatar style={{ width: 20, height: 20, fontSize: 12, padding: 1 }}>&#10006;</Avatar>
                        </InputAdornment>}
                    </>
                }
                required={required}
            />
            <Menu
                anchorEl={menu_anchor}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <div>
                    <Input
                        style={{ width: width, padding: 5, background: input_background }}
                        type='text'
                        placeholder={input_placeholder ? input_placeholder : 'Type here...'}
                        onChange={onHandleInput}
                    />
                </div>
                <div style={{ maxHeight: updated_list ? '250px' : '42px', overflowY: 'auto', background: option_background, color: option_background ? 'white' : '' }}>
                    <MenuItem
                        style={{
                            textAlign: 'left',
                            padding: 0,
                            fontSize: '14px',
                            fontFamily: 'cursive'
                        }}>
                        <Checkbox
                            disabled={!(updated_list?.length) ? true : false}
                            checked={data_list?.length && store_orignal_data_list?.length === selected_list?.length ? true : false}
                            onChange={() => onHandleCheck('select_all')}
                        />
                        <i><b>Select All</b></i></MenuItem>
                    {updated_list && updated_list.map((option, index) => (
                        <React.Fragment key={index}>
                            {/* Render Title */}
                            <div
                                style={{
                                    display: 'flex',
                                    width: width,
                                    overflow: 'hidden',
                                    whiteSpace: 'wrap',
                                    padding: '5px 0px',
                                    alignItems: 'center',
                                }}
                            >
                                <hr style={{ flex: '1', margin: '0 10px 0 0' }} />
                                <span><i><b>{option.title}</b></i></span>
                                <hr style={{ flex: '20', margin: '0 0 0 10px' }} />
                            </div>

                            {/* Render Data for the Title */}
                            {option.data.map((dataItem, dataIndex) => (
                                <MenuItem key={`${index}-${dataIndex}`}
                                    style={{
                                        fontSize: '14px',
                                        margin: 0,
                                        textAlign: 'left',
                                        width: width,
                                        overflow: 'hidden',
                                        whiteSpace: 'wrap',
                                        padding: '5px 0px',
                                        background: selected_list?.some(element => element[unique_key] === dataItem[unique_key]) ? 'rgba(177, 209, 243, 0.664)' : '',
                                    }}
                                >
                                    <Checkbox
                                        checked={selected_list?.some(element => element[unique_key] === (dataItem[unique_key]))}
                                        onChange={() => onHandleCheck(dataItem)}
                                    />
                                    {show_options_item && show_options_item(dataItem)}
                                </MenuItem>
                            ))}
                        </React.Fragment>
                    ))}

                </div>
            </Menu>
        </div>
    );
}