import React from 'react';
import Chart from 'react-apexcharts';

const DonutChart = ({ series, labels }) => {
  // const series = [44, 55, 41];
  const options = {
    chart: {
      type: 'donut',
    },
    title: {
      text: 'Total Scans',
      align: 'left',
      style: {
        color: 'gray',
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              offsetY: -15,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              color: 'gray',
              fontSize: '22px',
              fontFamily: 'Arial, sans-serif',
              fontWeight: 'bold',
              offsetY: 7,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: 'Scans',
              color: 'gray',
              fontSize: '22px',
              fontFamily: 'Arial, sans-serif',
              fontWeight: 'bold',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    colors: ['#F86F03', '#A76F6F', '#0065bd', '#00ff00', "#ffff00" , '#0000ff'],
    // labels: ['Wheat', 'Rice', 'Chana'],
    labels: labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'top',
          },
        },
      },
    ],
  };

  return <Chart options={options} series={series} type="donut" height={320} />;
};

export default DonutChart;