import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({path, children }) => {
  const authToken = localStorage.getItem("token");

  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  // if(path === '/' && authToken) {
  //   return <Navigate to="dashboard/home" replace />;
  //   // return <Navigate to="dashboard/users" replace />;

  // }

  return children;
};

export default ProtectedRoute;
