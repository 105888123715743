const user_initial_state = {
    user_count: 0,
    user_scans_count: 0,
    user_list: [],
    loading: false,
    error: null
}

const userReducer = (state = user_initial_state, action) => {
    switch (action.type) {
        case 'user_count_for_user_page':
            return {
                ...state,
                user_count: action.payload[0],
                user_scans_count: action.payload[1]
            }
        case 'USER_DATA_LIST':
            return {
                ...state,
                user_list: action.payload
            }
        case 'FETCH_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case 'CLAER_CACHE_REDUX_USER': {
            return user_initial_state
        }
        case 'LOGOUT': {
            return user_initial_state
        }
        default:
            return state;
    }
}

export default userReducer;
