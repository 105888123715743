import React, { useEffect, useState, useMemo } from 'react'
import { Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import LoadingUserPage from '../../widget/LoadingUserPage';
import UserCard from '../../widget/UserCard';
import GridOnIcon from '@mui/icons-material/GridOn';
import ListIcon from '@mui/icons-material/List';
import Table from '../../widget/Table';
import { useNavigate } from 'react-router-dom';
import FilterComponent from './FilterComponent';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getUserList } from '../../utils/api';

function User() {
  const user_cache = useSelector(state => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTermData] = useState('')
  const [filteredData, setFilteredData] = useState([]);
  const [isListViewTrueOrGridViewFalse, setIsListViewTrueOrGridViewFalse] = useState(false)
  const [sortAndFilter, setSortAndFilter] = useState({
    filter_type: '',
    name_sort_order: '',
    email_sort_order: ''
  })

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        if (user_cache?.user_list.length) {
          setUserList(user_cache?.user_list)
          setFilteredData(user_cache?.user_list)
        } else {
          const response = await getUserList()
          if (response?.data?.data) {
            const dataList = response?.data?.data?.user_list?.map((item, index) => (
              { ...item, id: index }
            ))
            setUserList(dataList)
            setFilteredData(dataList)
            dispatch({
              type: 'USER_DATA_LIST',
              payload: dataList
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  useMemo(() => {
    if (user_cache?.user_list.length > 0) {
      setUserList(user_cache?.user_list)
      setFilteredData(user_cache?.user_list)
    }
  }, [user_cache?.user_list?.length])

  useEffect(() => {
    let filteredRows = userList
    if (filteredRows.length > 0) {
      filteredRows = filteredRows.filter((row) => {
        const fullName = `${row.first_name} ${row.last_name}`;
        const email = row.email;
        const phoneNumber = row.phone_number;
        const searchTermLower = searchTerm.toLowerCase();
        return (
          (email && email.toLowerCase().includes(searchTermLower)) ||
          (phoneNumber && phoneNumber.toLowerCase().includes(searchTermLower)) ||
          fullName.toLowerCase().includes(searchTermLower)
        );
      });
    }
    setFilteredData(filteredRows)
  }, [searchTerm])

  useEffect(() => {
    if (sortAndFilter.filter_type === 'first_name') {
      const sortedData = [...userList].sort((a, b) => {
        const propertyA = a[sortAndFilter.filter_type];
        const propertyB = b[sortAndFilter.filter_type];

        if (propertyA === undefined || propertyB === undefined) {
          return 0;
        }

        if (propertyA === null) return -1;
        if (propertyB === null) return 1;

        const valueA = propertyA.toLowerCase();
        const valueB = propertyB.toLowerCase();

        if (valueA < valueB) {
          return sortAndFilter.name_sort_order === 'asc' ? -1 : 1;
        }

        if (valueA > valueB) {
          return sortAndFilter.name_sort_order === 'asc' ? 1 : -1;
        }

        return 0;
      });
      setFilteredData(sortAndFilter.name_sort_order === '' ? userList : sortedData)
    } else if (sortAndFilter.filter_type === 'phone_number') {
      const user_list = [...userList]
      user_list?.sort((a, b) => {
        if (a.phone_number && !b.phone_number) return -1;
        if (!a.phone_number && b.phone_number) return 1;
        if (a.phone_number && b.phone_number) return a.phone_number.localeCompare(b.phone_number);
        return 0;
      })
      user_list.sort((a, b) => {
        const aHasContactInfo = a.email && a.phone_number;
        const bHasContactInfo = b.email && b.phone_number;
        if (aHasContactInfo && !bHasContactInfo) return 1; // a has contact info, b doesn't, so a comes later
        else if (!aHasContactInfo && bHasContactInfo) return -1; // b has contact info, a doesn't, so b comes later
        else return 0; // both have contact info or both don't, maintain original order
      })
      setFilteredData(user_list)
    } else if (sortAndFilter.filter_type === 'email') {

      const result = userList.slice()
      const user_list = result.sort((a, b) => {
        if (!a.email && !b.email) return 0;
        else if (!a.email) return 1
        else if (!b.email) return -1;
        const emailComparison = a.email.localeCompare(b.email);
        return sortAndFilter.email_sort_order === 'asc' ? emailComparison : -emailComparison;
      })
      setFilteredData(sortAndFilter.email_sort_order === '' ? userList : user_list)
    }
    setSortAndFilter('')
    setSearchTermData('')
  }, [sortAndFilter])

  const handleUserdetail = (item) => {
    localStorage.setItem('user_id', item.firebase_user_id)
    navigate(`/dashboard/users/${item.firebase_user_id}`);
  }

  const renderLoading = () => {
    const elements = [];
    for (let row = 0; row < 3; row++) {
      const rowElements = [];
      for (let col = 0; col < 3; col++) {
        rowElements.push(
          <div
            key={`${row}-${col}`}
            style={{ margin: 10 }}
          >
            <LoadingUserPage />
          </div>
        );
      }
      elements.push(
        <div className='grid-template-columns' key={`row-${row}`} style={{ display: 'grid', gap: '5px', padding: '0px 0px' }}>
          {rowElements}
        </div>
      );
    }
    return elements;
  };

  const columns = [
    {
      field: 'Name',
      headerName: 'Name',
      width: '550',
      headerClassName: 'table-header',
      editable: false,
      valueGetter: (params) => { return params.row.first_name + " " + params.row.last_name },
    },
    {
      field: 'Email/Phone Number',
      headerName: 'Email/Phone Number',
      width: '550',
      headerClassName: 'table-header',
      editable: false,
      valueGetter: (params) => {
        return params.row.email ? params.row.email : params.row.phone_number
      },
    },
    {
      field: 'View',
      headerName: 'Action',
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'table-header',
      renderCell: (rowData) => (
        <Button className='APP-COLOR' onClick={() => handleUserdetail(rowData.row)}>View</Button>
      )
    }
  ];


  return (
    <div style={{backgroundColor: "rgb(241, 241, 241)"}}>
      <Box sx={{ padding: '25px 20px' }}>
        <Box className='d-flex justify-between'>
          <TextField
            label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTermData(e.target.value)}
            style={{ marginLeft: 9, marginBottom: 15, width: 267 }}
          />

          <div className='d-flex justify-between'>
            <div>
              {!isListViewTrueOrGridViewFalse && <FilterComponent setSortAndFilter={setSortAndFilter} />}
            </div>
            <div>
              <Tooltip title={isListViewTrueOrGridViewFalse ? 'Grid View' : "List View"}>
                <IconButton
                  size="small"
                  sx={{ ml: 2, p: 1 }}
                  aria-haspopup="true"
                  className='cursor border-none gray'
                >
                  {isListViewTrueOrGridViewFalse ?
                    <button className='cursor bg-none border-none APP-COLOR-2' onClick={() => setIsListViewTrueOrGridViewFalse(false)}><GridOnIcon /></button>
                    : <button className='cursor bg-none border-none APP-COLOR-2' onClick={() => setIsListViewTrueOrGridViewFalse(true)}><ListIcon /></button>
                  }
                </IconButton>
              </Tooltip>
            </div>
          </div>

        </Box>
        <Box>
          {!loading
            ? <div>
              {isListViewTrueOrGridViewFalse
                ? <div className="b-r-10 p-10" style={{ backgroundColor: 'white'}}>
                  <Table rows={filteredData} columns={columns} pageSize={15} />
                </div>
                : <div>
                  <div className='grid-template-columns' style={{ display: 'grid', gap: '10px' }}>
                    {filteredData && filteredData?.map((item, index) => (
                      <UserCard key={index} item={item} />
                    ))}
                  </div>
                  {filteredData && filteredData.length === 0 && <p className='align-center m-t-20'>There are no records to display</p>}
                </div>}
            </div>
            : renderLoading()}
        </Box>
      </Box>

    </div>
  )
}
                  
export default User