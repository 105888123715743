import { NavLink } from "react-router-dom";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from 'react-icons/bs'
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import Header from "../header/Header";
import routes from './Routes'
import { Divider } from "@mui/material";
import logo_image from '../assests/images/Upjao_logo.png'

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const inputAnimation = {
  //   hidden: {
  //     width: 0,
  //     padding: 0,
  //     transition: {
  //       duration: 0.2,
  //     },
  //   },
  //   show: {
  //     width: "140px",
  //     padding: "5px 15px",
  //     transition: {
  //       duration: 0.2,
  //     },
  //   },
  // };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "15%" : "50px",
            transition: {
              duration: 1,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar`}
        >
          <div className="sticky">
            <div className="top_section">
              <AnimatePresence>
                {isOpen ? (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                  // className="logo"
                  >
                    {/* UPJAO */}
                    <div style={{ width: "100% !important" }} className="m-t-20">
                      <img src={logo_image} width='100%' height={100} />
                    </div>

                  </motion.div>
                ) : <div style={{ width: "15% !important", }} className="m-t-20">
                  <img src={logo_image} width='100%' height='47px' />
                </div>}
              </AnimatePresence>

              {/* <div className="bars">
                {isOpen ? <BsArrowLeftCircleFill className="cursor" onClick={toggle} /> : <BsArrowRightCircleFill className="cursor" onClick={toggle} />}
              </div> */}
            </div>

            {/* <div className="search">
            <div className="search_icon">
              <BiSearch />
            </div>
            <AnimatePresence>
              {isOpen && (
                <motion.input
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  variants={inputAnimation}
                  type="text"
                  placeholder="Search"
                />
              )}
            </AnimatePresence>
          </div> */}


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: isOpen ? 'calc(100vh - 135px)' : 'calc(100vh - 80px)' }}>
              <section className="routes">
                {routes.map((route, index) => {
                  if (route.subRoutes) {
                    return (
                      <SidebarMenu
                        setIsOpen={setIsOpen}
                        route={route}
                        showAnimation={showAnimation}
                        isOpen={isOpen}
                      />
                    );
                  }

                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      className="link"
                    // activeClassName="active"
                    >
                      <div className="icon">{route.icon}</div>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                })}
              </section>

              {!(window.innerWidth <= 768) && <div>
                <Divider style={{ marginBottom: 10 }} />
                <div className="align-right p-r-10 APP-COLOR">
                  {isOpen ? <BsArrowLeftCircleFill className="cursor" onClick={toggle} /> : <BsArrowRightCircleFill className="cursor" onClick={toggle} />}
                </div>
              </div>}
            </div>
          </div>
        </motion.div>

        <main className="scrollbar" style={{ width: isOpen ? "85%" : "calc(100% - 50px)", backgroundColor: 'rgb(241, 241, 241)' }}>
          <div className="sticky"><Header /></div>
          {children}
        </main>
      </div>
    </>
  );
};

export default SideBar;
