import React, { useEffect, useState } from 'react'
import { getAccessPaymentHistroy } from '../../utils/api';
import { TextField } from '@mui/material';
import Table from '../../widget/Table';
import moment from 'moment/moment';

function AccessPaymentHistroy() {
    const [paymentAccessHistroy, setPaymentAccessHistroy] = useState([])
    const [filteredData, setFilterdData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            try {
                const responce = await getAccessPaymentHistroy()
                setPaymentAccessHistroy(responce?.data?.data?.accessHistory)
                setFilterdData(responce?.data?.data?.accessHistory)
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        })()
    }, [])

    useEffect(() => {
        onhandleSearch();
    }, [searchTerm]);

    const onhandleSearch = () => {
        if (paymentAccessHistroy) {
            const filteredData = paymentAccessHistroy.filter((payment) => (
                payment.number_of_scans.toLowerCase().includes(searchTerm.toLowerCase()) || 
                moment(payment.start_date).format('DD MMM, YYYY hh:mm A').toLowerCase().includes(searchTerm.toLowerCase()) ||
                moment(payment.end_date).format('DD MMM, YYYY hh:mm A').toLowerCase().includes(searchTerm.toLowerCase())
            )) 
            setFilterdData(filteredData);
        }
    };

    const columns = [
        {
            field: 'Start Date',
            headerName: 'Start Date',
            width: 440,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return moment(params.row.start_date).format('DD MMM, YYYY hh:mm A') },
        },
        {
            field: 'end_date',
            headerName: 'End Date',
            width: 440,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return moment(params.row.end_date).format('DD MMM, YYYY hh:mm A') },
        },
        {
            field: 'Number Of Scans',
            headerName: 'Number Of Scans',
            width: 330,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.unlimited_scans ? "Unlimited" : params.row.number_of_scans },
        },
    ];

    return (
        <div>
            <div>
                <div className='d-flex justify-between flex-direction-custom g-3'>
                    <h3><strong>Payment Access History</strong></h3>
                    <input style={{ padding: 10, borderRadius: 5, border: '1px solid skyblue' }} placeholder='Search' onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
                <div className="b-r-10 p-10 m-t-10" style={{ backgroundColor: 'white'}}>
                    <Table rows={filteredData} columns={columns} loading={loading} /> 
                </div>
            </div>
        </div>
    )
}

export default AccessPaymentHistroy