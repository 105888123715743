import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

function CarouselSlider({ imageList }) {
    const processedFormats = ['processed.webp', 'processed.png', 'processed.jpg', 'processed.jpeg'];
    const capturedFormats = ['captured.webp', 'captured.png', 'captured.jpg', 'captured.jpeg'];
    const [arrayList, setArrayList] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const minHeight = `calc(${window.innerHeight}px - 48px)`;

    useEffect(() => {
        const array = [];
        for (const format of capturedFormats) {
            const capturedImage = imageList.find((file) => file.file_name === format);
            if (capturedImage) {
                array.push(capturedImage);
                break;
            }
        } 
        if (array.length === 0) {
            const cvServerImage = imageList.find((file) => file.file_name.includes('_cv_server'));
            if (cvServerImage) {
                array.push(cvServerImage);
            }
        }
        for (const format of processedFormats) {
            const processedImage = imageList.find((file) => file.file_name === format);
            if (processedImage) {
                array.push(processedImage);
                break; // Add the first processed format found
            }
        }
        setArrayList(array);
    }, [imageList]);

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === arrayList.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? arrayList.length - 1 : prevIndex - 1
        );
    };

    const displayedImage = arrayList[currentImageIndex];

    return (
        <div style={{ height: minHeight }}>
            {arrayList.length ? (
                <div className="image-slider" style={{ backgroundColor: 'black' }}>
                    <div className="image-list">
                        {arrayList.map((image, index) => (
                            <div
                                key={index}
                                className={`image-item ${index === currentImageIndex ? 'active' : ''}`}
                                onClick={() => setCurrentImageIndex(index)}
                            >
                                <img
                                    style={{ width: 150, height: 150 }}
                                    src={image.image}
                                    alt={`Slider ${index}`}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="slider-container">
                        <button className="prev-button" onClick={handlePrevImage}>
                            &lt;
                        </button>
                        <Box sx={{ height: '90%', display: 'flex', flexDirection: 'column' }}>
                            <img src={displayedImage.image} alt="Slider" />
                            <h4 style={{ color: 'white', textAlign: 'center' }}>{displayedImage.file_name}</h4>
                        </Box>
                        <button className="next-button" onClick={handleNextImage}>
                            &gt;
                        </button>
                    </div>
                </div>
            ) : (
                <Box sx={{ m: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
        </div>
    );
}

export default CarouselSlider;
