import React, { useEffect, useState, useMemo } from 'react'
import { Box, Card, CircularProgress } from '@mui/material'
import DatePicker from 'react-multi-date-picker'
import StackedBarChart from '../../charts/StackedBarChart'
import { getMonthAbbreviation } from '../../utils/common'

function DateWithGradeChart({ data_list, total_days_range, selectedDateOption }) {
    const [uniqueCropList, setUniqueCropList] = useState([])
    const [selected_crop, setSelectedCrop] = useState('')
    const [date_list_for_chart, setDateListForChart] = useState([])
    const [show_serial_data_for_date_wise, setShowSerialDataForDateWise] = useState([])
    const [date_range, setDateRange] = useState('')
    const [is_reset_data, setIsResetData] = useState(false)
    const [loading, setLoading] = useState(false)
    const extra_day = 30

    useEffect(() => {
        onLoadGraph()
    }, [data_list])

    useEffect(() => {
        if (is_reset_data) {
            onHandleDateVsGrade(data_list, uniqueCropList[0])
            setIsResetData(false)
        }
    }, [is_reset_data])

    const onSetDefaultDate = () => {
        const oneDay = 24 * 60 * 60 * 1000;
        const start = new Date(total_days_range.startDate);
        const end = new Date(total_days_range.endDate);
        const differenceMs = Math.abs(end - start);
        const days_diffrence = Math.round(differenceMs / oneDay);
        let add_extra_day = 0;
        if (days_diffrence <= 30) {
            add_extra_day = days_diffrence
        } else {
            add_extra_day = extra_day
        }

        let start_date;
        let end_date;

        if (selectedDateOption === "this_year" || selectedDateOption === "this_month" || selectedDateOption === "custom") {
            start_date = new Date(end);
            start_date.setDate(start_date.getDate() - add_extra_day + 1);
            end_date = new Date(end);
        } else if (selectedDateOption === "today") {
            start_date = new Date();
            // start_date.setDate(start_date.getDate());
            end_date = new Date();
        } else {
            start_date = new Date();
            start_date.setDate(start_date.getDate() - add_extra_day);
            end_date = new Date();
        }

        setDateRange([start_date, end_date]);
    }

    const getFilteredDataAccordingToDate = (filtered_data_list) => {
        if (date_range && date_range.length === 2) {
            let [start_date, end_date] = date_range;
            start_date = new Date(start_date);
            end_date = new Date(end_date);
            start_date.setHours(0, 0, 0, 0);
            end_date.setHours(23, 59, 59, 999);
            filtered_data_list = filtered_data_list.filter(item => {
                const scan_date = new Date(item.scan_date);
                return scan_date >= start_date && scan_date <= end_date;
            });
        }
        return filtered_data_list
    }

    const onHandleDateVsGrade = (data_list, selected_crop) => {
        const gradeRanges = [
            { name: "Grade A", minScore: 90, maxScore: 100 },
            { name: "Grade B", minScore: 80, maxScore: 90 },
            { name: "Grade C", minScore: -1, maxScore: 80 },
        ];

        // Function to format date as "dd-mm-yyyy"
        const formatDate = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
        };

        let filteredDataList = data_list

        // crops filter
        if (selected_crop) {
            filteredDataList = data_list.filter(
                item => (selected_crop === item?.crop_details?.crop) || (selected_crop === item?.crop)
            )
        }
        // Date filter
        filteredDataList = getFilteredDataAccordingToDate(filteredDataList)

        if (!filteredDataList.length) {
            setDateListForChart([])
            setShowSerialDataForDateWise([])
            setTimeout(() => {
                setLoading(false)
            }, [1000])
            return
        }

        const output_data = {};
        filteredDataList.forEach((item) => {
            const formattedDate = formatDate(item.scan_date);
            // Check if the necessary properties exist
            if (item.scan_results && item.scan_results.scan_result && item.scan_results.scan_result.kernel_details && item.scan_results.scan_result.kernel_details.overall_score) {
                const overallScore = parseFloat(item.scan_results.scan_result.kernel_details.overall_score);
                let grade = "Grade C";

                for (const range of gradeRanges) {
                    if (overallScore >= range.minScore && overallScore <= range.maxScore) {
                        grade = range.name;
                        break;
                    }
                }
                if (!output_data[formattedDate]) {
                    output_data[formattedDate] = {};
                }

                if (!output_data[formattedDate][grade]) {
                    output_data[formattedDate][grade] = 1;
                } else {
                    output_data[formattedDate][grade]++;
                }
            }
        });

        // Sort the dates in descending order
        const sortedDates = Object.keys(output_data).sort((a, b) => new Date(b) - new Date(a));

        // Create the final output with sorted dates
        const sortedOutputData = {};
        sortedDates.forEach((date) => {
            sortedOutputData[date] = output_data[date];
        });

        // Initialize grade_wise_list with zero-filled arrays
        const grade_wise_list = gradeRanges.map((grade) => ({
            name: grade.name,
            data: Array(sortedDates.length).fill(0),
        }));

        // Populate grade_wise_list with actual data
        gradeRanges.forEach((grade, gradeIndex) => {
            sortedDates.forEach((date, dateIndex) => {
                if (sortedOutputData[date][grade.name]) {
                    grade_wise_list[gradeIndex].data[dateIndex] = sortedOutputData[date][grade.name];
                }
            });
        });

        const datesWithoutYearAndMonth = sortedDates.map(dateString => {
            const [day, month] = dateString.split('-');
            return `${day} ${getMonthAbbreviation(month)}`;
        });

        setDateListForChart(datesWithoutYearAndMonth)
        setShowSerialDataForDateWise(grade_wise_list)
        setTimeout(() => {
            setLoading(false)
        }, [1000])
    }

    const onHandleShowCropAndDateGraph = () => {
        setLoading(true)
        onHandleDateVsGrade(data_list, selected_crop)
    }

    const onHandleCropDropdown = (e) => {
        setSelectedCrop(e.target.value)
        onSetDefaultDate()
    }

    const onHandleResetData = () => {
        setSelectedCrop(uniqueCropList[0])
        onSetDefaultDate()
        setIsResetData(true)
    }

    const onLoadGraph = () => {
        // unique crops
        let uniqueCropData;
        if (data_list[0]?.crop) {
            uniqueCropData = Array.from(new Set(data_list.map(item => item?.crop)));
        } else {
            uniqueCropData = Array.from(new Set(data_list.map(item => item?.crop_details?.crop)));
        }
        setUniqueCropList(uniqueCropData)
        setSelectedCrop(uniqueCropData[0])
        onSetDefaultDate()
        onHandleDateVsGrade(data_list, selected_crop)
    }

    useMemo(() => {
        onLoadGraph()
    }, [data_list])

    return (
        <div>
            {<Card sx={{ mt: 2, p: 0, boxShadow: 0, borderRadius: '18px' }}>
                <Card sx={{ boxShadow: 0, borderRadius: '18px' }}>
                    <Box sx={{ m: 3, display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'start', gap: 1 }}>
                            <Box style={{ display: 'flex', flexDirection: 'column' }} className='rs-width-100'>
                                <label className='gray' style={{ textAlign: 'start' }}><b>Crops</b></label>
                                <select onChange={onHandleCropDropdown} className='p-8 b-5 border-darkblue'>
                                    <option selected={selected_crop === ''}>Select</option>
                                    {uniqueCropList && uniqueCropList.map((crop, index) => (
                                        <option key={index} value={crop} selected={crop === selected_crop}>
                                            {crop}
                                        </option>
                                    ))}
                                </select>
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column' }} className='rs-width-100'>
                                <label className='gray' style={{ textAlign: 'start' }}><b>Date</b></label>
                                <DatePicker
                                    range
                                    rangeHover
                                    placeholder=" Start Date ~ End Date "
                                    shadow
                                    numberOfMonths={1}
                                    value={date_range}
                                    onChange={setDateRange}
                                    maxDate={new Date()}
                                    style={{ padding: '5px', height: '35px' }}
                                // disabled={number_of_days <= 30 ? true : false}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
                            <div>
                                <button
                                    disabled={!selected_crop}
                                    onClick={onHandleShowCropAndDateGraph}
                                    className='APP-BACKGROUND border-none border-app-color-2 white cursor m-t-20 b-r-10'
                                    style={{ padding: '10px 20px', height: 35 }}
                                >
                                    <b>View</b>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => onHandleResetData()}
                                    className='APP-BACKGROUND-2 border-none border-app-color-2 white cursor m-t-20 b-r-10'
                                    style={{ padding: '10px 20px', height: 35 }}>
                                    <b>Reset</b>
                                </button>
                            </div>
                        </Box>
                    </Box>
                    <Box sx={{ p: 1 }}>
                        {loading
                            ? <CircularProgress sx={{ color: '#b34d00', margin: 2 }} size={25} />
                            : <StackedBarChart seriesData={show_serial_data_for_date_wise} categoriesData={date_list_for_chart} title="Grade Vs Date" xaxis_title='Date' yaxis_title='Number Of Scans' />}
                    </Box>

                </Card>
            </Card>}
        </div>
    )
}

export default DateWithGradeChart