import React from 'react'
import { Route, Routes } from "react-router-dom";
import User from '../views/users/User';
import SideBar from '../sidebar/SideBar';
import Home from '../views/Home'
import UserDetails from '../views/users/UserDetails';
import Setting from '../views/setting/Setting';

function Dashboard() {
    return (
        <SideBar>
            <Routes>
                <Route path="/users" element={<User />} />
                <Route path="/users/:id" element={<UserDetails />} />
                <Route path="/home" element={<Home />} />
                <Route path="/setting" element={<Setting />} />
                <Route path="/*" element={<>Not Found</>} />
            </Routes>
        </SideBar>
    )
}

export default Dashboard