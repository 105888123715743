import api from "./interceptor";

export const getUserList = async () => {
    try {
        const response = await api.post(`/dashboard/user_list`);
        return response
    } catch (error) {
        console.log(error);
    }
}

export const getUserById = async (firebase_user_id, start_date, end_date) => {
    try {
        const pay_load = {
            firebase_user_id: firebase_user_id,
            start_date: start_date,
            end_date: end_date
        }
        const response = await api.post(`/dashboard/get_user_details`, pay_load);
        return response
    } catch (error) {
        console.log(error);
    }
}

// all crops
export const getAllCrops = async () => {
    try {
        const response = await api.post(`/crops/get/company`);
        return response
    } catch (error) {
        console.log(error);
    }
}

// get all machine
export const getAllMachine = async () => {
    try {
        const response = await api.post(`/machines/get/company`);
        return response
    } catch (error) {
        console.log(error);
    }
}

// pdf view
export const getScanPdf = async (scanId, subScanId) => {
    try {
        const pay_load = { "scan_id": scanId, "pdf_type": "LONG" };
        if (subScanId) {
            pay_load["sub_scan_id"] = subScanId;
        }
        const response = await api.post(`/scans/generate_scan_pdf_merged`, pay_load, { responseType: 'blob' });
        return response
    } catch (error) {
        console.error(error);
        return error
    }
};


// getting scans images list
export const getScanFiles = async (scanId, subScanId) => {
    try {
        const pay_load = { "scan_id": scanId, "sub_scan_id": subScanId }
        const response = await api.post(`/dashboard/get_scan_files`, pay_load);
        return response
    } catch (error) {
        console.error(error);
    }
}

// Heat map data
export const getHeatmapData = async (pay_load) => {
    try {
        const response = await api.post(`/dashboard/heatmap/get_scans/crops`, pay_load);
        return response
    } catch (error) {
        console.error(error);
    }
}

// total scans for home page
export const getScansCount = async () => {
    try {
        const response = await api.post(`/dashboard/get_scan_count`);
        return response
    } catch (error) {
        console.error(error);
    }
}

// user count for home page
export const getUsersCount = async () => {
    try {
        const response = await api.post(`/dashboard/get_user_count`);
        return response
    } catch (error) {
        console.error(error);
    }
}

// total scans verse crop in home
export const getScansDataUsingCrops = async (pay_load) => {
    try {
        const response = await api.post(`/dashboard/get_scans`, pay_load);
        return response
    } catch (error) {
        console.error(error);
    }
}

//  generate_scan_pdf_merged_for_allScans
export const getAllScanPdf = async (pdfDataArray) => {
    try {
        const extractedDataArray = pdfDataArray.map(({ scan_id, pdf_type }) => ({ scan_id, pdf_type }));
        const pay_load = { scanDataArray: extractedDataArray }
        const response = await api.post(`/scans/generate_scan_pdf_merged_for_all_scans`, pay_load, { responseType: 'blob' });
        const blob = response.data;
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'crops_pdf.zip';
        link.click();
    } catch (error) {
        console.error(error);
    }
}

export const getUserCSV = async (scanRequests, firebase_user_id) => {
    try {
        scanRequests = scanRequests.map(item => ({ 'scan_id': item.scan_id }));
        const pay_load = {
            scanRequests: scanRequests,
            firebase_user_id: firebase_user_id
        }
        const response = await api.post(`/scans/get_scan_result_merged_for_allScans`, pay_load, { responseType: 'blob' });
        const blob = response.data;
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'crop_csv.zip';
        link.click();
    } catch (error) {
        console.error(error);
    }
}

export const getScanCsvAccordingToCrop = async (scanRequests, firebase_user_id, crop_name) => {
    try {
        scanRequests = scanRequests.map(item => ({ 'scan_id': item.scan_id }));
        const seenScanIds = new Set();
        const uniqueObjects = [];
        for (const obj of scanRequests) {
            const scanId = obj.scan_id;
            if (!seenScanIds.has(scanId)) {
                seenScanIds.add(scanId);
                uniqueObjects.push(obj);
            }
        }
        const pay_load = {
            scanRequests: uniqueObjects,
            firebase_user_id: firebase_user_id
        }
        const response = await api.post(`/scans/get_scan_result_merged_csv_for_allScans`, pay_load, { responseType: 'blob' });
        const blob = response.data;
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${crop_name}.csv`;
        link.click();
    }
    catch (error) {
        console.error(error);
    }
}
export const getCsvForAllScans = async (scanRequests, firebase_user_id, crop_name) => {
    try {
        scanRequests = scanRequests.map(item => ({ 'scan_id': item.scan_id }));
        const seenScanIds = new Set();
        const uniqueObjects = [];
        for (const obj of scanRequests) {
            const scanId = obj.scan_id;
            if (!seenScanIds.has(scanId)) {
                seenScanIds.add(scanId);
                uniqueObjects.push(obj);
            }
        }
        const pay_load = {
            scanRequests: uniqueObjects,
            firebase_user_id: firebase_user_id
        }
        const response = await api.post(`/scans/get_csv_for_allScans`, pay_load);
        return response
    }
    catch (error) {
        console.error(error);
    }
}

// Setting APIs Start
export const getUserApprovalData = async () => {
    try {
        const response = await api.post(`/company/get/users`);
        return response
    } catch (error) {
        console.error(error);
    }
}

export const getPaymentHistroy = async () => {
    try {
        const response = await api.post(`/company/payment/history`);
        return response
    } catch (error) {
        console.error(error);
    }
}

export const getAccessPaymentHistroy = async () => {
    try {
        const response = await api.post(`/company/payment/accessHistory`);
        return response
    } catch (error) {
        console.error(error);
    }
}

export const getCompanyMachine = async () => {
    try {
        const response = await api.post(`/machines/get/company`);
        return response
    } catch (error) {
        console.error(error);
    }
}

export const getCompanyCrops = async () => {
    try {
        const response = await api.post(`/crops/get/company`);
        return response
    } catch (error) {
        console.error(error);
    }
}

export const getCompanyMetaData = async () => {
    try {
        const response = await api.post(`/scans/get_company_metadata`);
        return response
    } catch (error) {
        console.error(error);
    }
}
export const getDefaultMetadata = async () => {
    try {
        const response = await api.get(`/scans/get_default_metadata`);
        return response
    } catch (error) {
        console.error(error);
    }
}
export const createNewUser = async ([pay_load]) => {
    try {
        const response = await api.post(`/input/create_user`, pay_load);
        return response
    } catch (error) {
        console.error(error);
    }
}

export const updateExistingUser = async (pay_load) => {
    try {
        const response = await api.post(`/users/update/other_user`, pay_load);
        return response
    } catch (error) {
        console.error(error);
    }
}

export const approveUser = async (firebase_user_id) => {
    try {
        const pay_load = { firebase_user_id: firebase_user_id }
        const response = await api.post(`/company/verify/user`, pay_load);
        return response
    } catch (error) {
        console.error(error);
    }
}

export const rejectUser = async (firebase_user_id) => {
    try {
        const pay_load = { firebase_user_id: firebase_user_id }
        const response = await api.post(`/company/unverify/user`, pay_load);
        return response
    } catch (error) {
        console.error(error);
    }
}

export const getCompanyInvoice = async (payment_id) => {
    try {
        const pay_load = { payment_id: payment_id }
        const response = await api.post(`/company/generate_invoice`, pay_load);
        return response
    } catch (error) {
        console.error(error);
    }
}
// Setting APIs End