import React, { useState, useEffect } from 'react'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { getCsvForAllScans } from '../../utils/api';
import CircularLoadingPopUp from '../../widget/CircularLoadingPopUp';
import { exportToCSVForAllScans } from '../../utils/common';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid aliceblue',
    boxShadow: 24,
    p: 4,
};

function ExportCsvPopUp({ open, setOpen, allScansList, selectedRowData }) {
    const [selectedScanData, setSelectedScanData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                const newOutput = {};
                allScansList.forEach(item => {
                    if (selectedRowData.some(selectedItem => selectedItem.scan_id === item.scan_id)) {
                        // console.log(item.scan_id, item.crop_details.crop);
                        if (!newOutput[item.crop_details.crop]) {
                            newOutput[item.crop_details.crop] = [];
                        }
                        newOutput[item.crop_details.crop].push({
                            scan_id: item.scan_id,
                            sub_scan_id: item.sub_scan_id
                        });
                    }
                });
                setSelectedScanData(newOutput);
            } catch (error) {
                console.error(error);
            }
        })()
    }, [allScansList, selectedRowData]);

    const getCsvFile = async (crop_name, crop_scan_list) => {
        try {
          setLoading(true);
          const path = window.location.pathname;
          const firebaseUserId = path.split("/").pop();
          console.log(crop_scan_list, firebaseUserId, crop_name);
          const response = await getCsvForAllScans(crop_scan_list, firebaseUserId, crop_name);
          if (response?.data?.data) {
            await exportToCSVForAllScans(response.data.data.scanResults, response.data.data.user, crop_name);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
      

    const handleClose = () => { setOpen(!open) }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <b>Crop Wise CSV download...</b>
                    </Typography>

                    <Box>
                        {loading ? <CircularLoadingPopUp /> : <div className='m-t-20'>
                            {Object.keys(selectedScanData).map(item => (
                                <Button key={item} className='bg-dark-green m-r-10 m-t-10' style={{ color: 'white', border: 'none', padding: '5px 15px' }} onClick={() => getCsvFile(item, selectedScanData[item])}>
                                    {item}
                                </Button>
                            ))}
                        </div>}
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ExportCsvPopUp