import React, { useEffect, useState } from 'react'
import { getCompanyMachine } from '../../utils/api';
import Table from '../../widget/Table';

function Machine() {
    const [companyMachines, setCompanyMachines] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); 
    const [loading, setLoading] = useState(true)
    const [filteredData, setFilterdData] = useState([])

    useEffect(() => {
        (async () => {
            try {
                const responce = await getCompanyMachine()
                const dataList = responce?.data?.data && responce?.data?.data.machines_data.map((item, index) => (
                    { ...item, id: index }
                  ))
                  responce?.data?.data.machines_data && setCompanyMachines(dataList)
                  responce?.data?.data.machines_data && setFilterdData(dataList)
                  setLoading(false)
            } catch (error) {
                console.error(error);
            }
        })()
    }, [])

    useEffect(() => {
        onhandleSearch();
    }, [searchTerm]);

    const onhandleSearch = () => {
        if (companyMachines) {
            const filteredData = companyMachines.filter((machine) => (
                machine.machine_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                machine.machine_code.toLowerCase().includes(searchTerm.toLowerCase())
            ))
            setFilterdData(filteredData);
        }
    };

    const columns = [
        {
            field: 'Machine Name',
            headerName: 'Machine Name',
            width: 250,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.machine_name },
        },
        {
            field: 'Machine Code',
            headerName: 'Machine Code',
            width: 250,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.machine_code },
        },
    ];

    return (
        <div>
            <div>
                <div className='d-flex justify-between flex-direction-custom g-3'>
                    <h3><strong>Machine</strong></h3>
                    <input style={{padding:10, borderRadius: 5, border: '1px solid skyblue'}} placeholder='Search' onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
                <div className="b-r-10 p-10 m-t-10" style={{ backgroundColor: 'white'}}><Table rows={filteredData} columns={columns} loading={loading}/></div>
            </div>
        </div>
    )
}

export default Machine