import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './routes/AppRoute';

function App() {
  return (
    <div className="App">
      <AppRoute />
    </div>
  );
}

export default App; 