import React, { useEffect } from 'react';
import maplibre from 'maplibre-gl';
import GeoJSON from 'geojson'
import 'maplibre-gl/dist/maplibre-gl.css';

const MapComponent = ({ data, dataArray }) => {
  useEffect(() => {
    const apiKey = "ZOrHkYad4ocDPfZvaMNV"

    const finalDataArray = dataArray && dataArray.map((item) => {
      return {
        crop: `${item.crop} (${item.variety})`,
        machine: `${item.machine_name} (${item.machine_code})`,
        scan_id: `${item.scan_id}-${item.sub_scan_id}`,
        lat: item.scan_metadata?.latitude,
        long: item.scan_metadata?.longitude,
        overall_score: item.overall_score
      }
    }).filter((item) => item.lat && item.long)
    
    const geojsonFinal = GeoJSON.parse(finalDataArray, { Point: ['lat', 'long'] })

    const map = new maplibre.Map({
      container: 'map',
      // style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`,
      style: 'https://api.maptiler.com/maps/basic/style.json?key='+apiKey,
      center: [78.9629, 23.5937], // Center point of India
      zoom: 4
    });

    map.addControl(new maplibre.NavigationControl(), 'top-left');
    map.scrollZoom.disable();

    map.on('load', () => {
      // Add a new source from our GeoJSON data and
      // set the 'cluster' option to true. GL-JS will
      // add the point_count property to your source data.
      map.addSource('finalData', {
        type: 'geojson',
        data: geojsonFinal,
        cluster: true,
        clusterMaxZoom: 15, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'finalData',
        filter: ['has', 'point_count'],
        paint: {
          // Use step expressions (https://maplibre.org/maplibre-style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'finalData',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'finalData',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 4,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      // inspect a cluster on click
      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        const clusterId = features[0].properties.cluster_id;
        map.getSource('finalData').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom
            });
          }
        );
      });

      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { crop, machine, scan_id, overall_score } = e.features[0].properties;

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new maplibre.Popup()
          .setLngLat(coordinates)
          .setHTML(
            `<div>
              <div><b>Crop:</b> ${crop}</div>
              <div><b>Machine:</b> ${machine}</div>
              <div><b>Scan ID:</b> ${scan_id}</div>
              <div><b>Overall Score:</b> ${overall_score}</div>
            </div>`
          )
          .addTo(map);
      });

      map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = '';
      });
    });

  }, [dataArray]);

  return <div id="map" style={{ height: '100vh' }} />;
};

export default MapComponent;
