import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth'
import CallIcon from '@mui/icons-material/Call';
import { useDispatch } from 'react-redux';
import { MdEmail } from "react-icons/md";
import { clearReduxForHomeComponent } from '../actions/home_action';
import { clearReduxForUserComponent } from '../actions/user_actions';

export default function ProfileMenuList() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const logout = async () => {
        await signOut(auth).then(() => {
            localStorage.clear();
            dispatch(clearReduxForHomeComponent())
            dispatch(clearReduxForUserComponent())
            navigate("/login");
        }).catch((error) => {
            console.error(error);
        });
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Profile">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar src={localStorage.getItem("photoURL")} sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        // '& .MuiAvatar-root': {
                        //     width: 32,
                        //     height: 32,
                        //     ml: -0.5,
                        //     mr: 1,
                        // },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box className='d-flex justify-center align-center flex-direction-column' style={{ minWidth: 250 }}>
                    <Avatar className='m-t-10 APP-BACKGROUND m-b-10' sx={{ width: 50, height: 50, }}>
                        <p className='m-0 p-0 fs-26'>{localStorage.getItem('user_name').substring(0, 1)}</p>
                    </Avatar>
                    <p className='m-0 p-0 p-l-5 fs-20'><b>{localStorage.getItem('user_name') ? localStorage.getItem('user_name') : 'No Name'}</b></p>
                    <p className='m-0 p-0 p-l-5 d-flex justify-center align-center flex-direction-column'>
                        {(localStorage.getItem('email') || localStorage.getItem('phoneNumber')) && <span>{localStorage.getItem('email')
                            ? <span className='d-flex justify-center align-center g-3'>
                                <span><MdEmail className='APP-COLOR' style={{ width: 15, height: 15, marginTop: 10 }} /></span>
                                <span>{localStorage.getItem('email')}</span>
                            </span>
                            : <span className='d-flex justify-start align-center g-3'><CallIcon className='APP-COLOR' style={{ width: 15, height: 15, marginTop: 10 }} /> {localStorage.getItem('phoneNumber')}</span>}
                        </span>}
                        <span>Access Level: {localStorage.getItem('user_role') ? localStorage.getItem('user_role') : ''}</span>
                    </p>
                    <p className='p-l-5'></p>
                </Box>
                <Divider />
                <MenuItem onClick={() => logout()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}