import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import { Box, LinearProgress } from '@mui/material';
import ImageSlider from './ImageSlider';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: '600px'
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function MessageModel({ open, onClose, type, pdfLink, setPdfLink }) {

    const navigate = useNavigate()
    const onHandleClose = () => {
        onClose(!open)
        setPdfLink([])
    }

    useEffect(() => {
        const handleBrowserBackButton = () => {
            if (open) {
                navigate(`/dashboard/users/${localStorage.getItem('user_id')}`)
            }
        };

        window.addEventListener('popstate', handleBrowserBackButton);

        return () => {
            window.removeEventListener('popstate', handleBrowserBackButton);
        };
    }, [open]);

    return (
        <div>
            <BootstrapDialog
                fullScreen
                onClose={() => onClose(!open)}
                open={open}
            >
                <Box sx={{ p: 1, display: 'flex', gap: '20px' }}>
                    <Box
                        sx={{
                            p: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'blue',
                            borderRadius: '50%',
                            width: '32px',
                            height: '32px',
                        }}
                    >
                        <IconButton
                            aria-label="go-back"
                            onClick={onHandleClose}
                            sx={{
                                color: 'white',
                                fontSize: '20px',
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ padding: '5px' }}>
                        <Typography>
                            {type === 'pdf' ? 'Scan Pdf' : 'Scan Images'}
                        </Typography>
                    </Box>
                </Box>
                <DialogContent dividers style={{ width: '100%', height: '100%', padding: '0px' }}>
                    {pdfLink ? <div style={{ width: 'inherit', height: 'inherit' }}>
                        {(pdfLink && type === 'pdf') && <embed
                            title='pdf'
                            src={pdfLink}
                            style={{ width: '100%', height: '100%', }}
                        ></embed>}
                        {type === 'image' && <ImageSlider imageList={pdfLink} />}
                    </div> : <LinearProgress />}
                </DialogContent>

                {/* <DialogActions>
                    <Button autoFocus onClick={() => onClose(!open)}>
                        Close
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>
        </div>
    );
}