import React from 'react'
import ProfileMenuList from './ProfileMenuList';

function Header() {
    return (
        <div>
            <div className='d-flex justify-between APP-BACKGROUND p-5'>
                <div className='d-flex align-center'>
                    <p className='p-l-5 white'><b>{localStorage.getItem('company_name')}</b></p>
                </div>
                <ProfileMenuList />
            </div>
        </div>
    )
}

export default Header