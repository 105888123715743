import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../views/Login";
import Dashboard from "./Dashboard"
import ProtectedRoute from "./ProtectedRoute";

function AppRoute() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    <ProtectedRoute path={'/'}>
                        <Login />
                    </ProtectedRoute>
                } />
                <Route path="/dashboard/*" element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<>Not found</>} />
            </Routes>
        </Router>
    );
}

export default AppRoute;
