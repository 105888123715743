import React, { useEffect, useState } from 'react'
import { approveUser, getUserApprovalData, rejectUser } from '../../utils/api';
import Table from '../../widget/Table';
import { Button, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CreateAndUpdateUser from './CreateAndUpdateUser';
import AppConformationModel from '../../widget/AppConformationModel';
import AppMessageModel from '../../widget/AppMessageModel';
import CircularLoadingPopUp from '../../widget/CircularLoadingPopUp';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

function UsersApprovalList() {
    const [usersApprovalList, setUsersApprovalList] = useState([])
    const [filteredData, setFilterdData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false)
    const [openAddUserModel, setOpenAddUserModel] = useState(false);
    const [editRow, setEditRow] = useState('')
    const [openConformationModel, setOpenConformationModel] = useState(false);
    const [title, setTitle] = useState('')
    const [subTitle, setSubTitle] = useState('')
    const [message, setMessage] = useState('')
    const [name, setName] = useState('')
    const [userFirebaseId, setUserFirebaseId] = useState('')
    const [successfulMessage, setSuccessfulMessage] = useState(false)
    const [successfulMessageLoading, setSuccessfulMessageLoading] = useState(false)
    const [isAddOrEditUser, setIsAddOrEditUser] = useState(false)

    useEffect(() => {
        (() => {
            getAllUserApprovalData()
        })()
    }, [])

    useEffect(() => { onhandleSearch() }, [searchTerm]);
    useEffect(() => { getAllUserApprovalData(); setSearchTerm('') }, [isAddOrEditUser]);

    const getAllUserApprovalData = async () => {
        try {
            const responce = await getUserApprovalData()
            setUsersApprovalList(responce?.data?.data?.users)
            setFilterdData(responce?.data?.data?.users)
            setLoading(true)
        } catch (error) {
            console.error(error);
        }
    }

    const onhandleSearch = () => {
        if (usersApprovalList) {
            const filteredData = usersApprovalList.filter((user) => (
                user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.phone_number && user.phone_number.toLowerCase().includes(searchTerm.toLowerCase())
            ))
            setFilterdData(filteredData);
        }
    };

    const onConfirmEdit = (row) => {
        setOpenAddUserModel(true)
        setEditRow(row)
    }

    const onHandleConformation = (title, user) => {
        console.log(user);
        setOpenConformationModel(true)
        setTitle(title)
        setSubTitle(user.email
            ? <p className='d-flex g-3'><MailOutlineIcon /> {user.email}</p>
            : <p><CallIcon className='d-flex g-3' /> {user.phone_number}</p>
        )
        setMessage(title === 'Reject' ? 'Do You want to reject this User ? ' : 'Do You want to approve this User ? ')
        setUserFirebaseId(user.firebase_user_id)
        setName(`${user.first_name} ${user.last_name}`)
    }

    const onRejectUser = async () => {
        setSuccessfulMessageLoading(true)
        try {
            const result = await rejectUser(userFirebaseId)
            console.log(result);
            if (result.status === 200) {
                setMessage("User Rejected successfully.");
                setTitle("Success")
                setSuccessfulMessage(true)
                await getAllUserApprovalData()
            }
        } catch (error) {
            console.error(error);
            setMessage("Something went wrong. Please try again later !!!");
            setTitle("Error !!!")
            setSuccessfulMessage(true)
        }
        setUserFirebaseId('')
        setSuccessfulMessageLoading(false)
    }

    const onApproveUser = async () => {
        setSuccessfulMessageLoading(true)
        try {
            const result = await approveUser(userFirebaseId)
            console.log(result);
            if (result.status === 200) {
                setMessage("User approved successfully.");
                setTitle("Success")
                setSuccessfulMessage(true)
                await getAllUserApprovalData()
            }
        } catch (error) {
            console.error(error);
            setMessage("Something went wrong. Please try again later !!!");
            setTitle("Error !!!")
            setSuccessfulMessage(true)
        }
        setUserFirebaseId('')
        setSuccessfulMessageLoading(false)
    }

    const columnsL1 = [
        {
            field: 'Name',
            headerName: 'Name',
            width: 550,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return `${params.row.first_name} ${params.row.last_name}` },
        },
        {
            field: 'Email/Phone',
            headerName: 'Email/Phone',
            width: 537,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.email ? params.row.email : params.row.phone_number },
        },
    ];

    const columnsL0 = [
        {
            field: 'Name',
            headerName: 'Name',
            width:  420,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return `${params.row.first_name} ${params.row.last_name}` },
        },
        {
            field: 'Email/Phone',
            headerName: 'Email/Phone',
            width: 405,
            headerClassName: 'table-header',
            editable: false,
            valueGetter: (params) => { return params.row.email ? params.row.email : params.row.phone_number },
        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 193,
            headerClassName: 'table-header',
            editable: false,
            renderCell: (params) => (
                <div>
                    {params.row.verified
                        ? <button className='b-5 bg-red cursor' style={{ color: 'white', border: 'none', padding: '5px 15px' }} onClick={() => onHandleConformation('Reject', params.row)}><span className='d-flex justify-between g-3'><p >Reject</p></span></button>
                        : <button className='b-5 bg-dark-green cursor' style={{ color: 'white', border: 'none', padding: '5px 15px' }} onClick={() => onHandleConformation('Approve', params.row)}><span className='d-flex justify-between g-3'><p >Approve</p></span></button>
                    }
                </div>
            )
        },
        {
            field: 'Edit',
            headerName: 'Update',
            width: 193,
            headerClassName: 'table-header',
            editable: false,
            renderCell: (params) => (
                <div style={{alignItems: 'center', borderRadius: '5px'}} className='APP-BACKGROUND'>
                    <ModeEditIcon className='cursor' style={{color: 'white', padding: 2}} onClick={() => onConfirmEdit(params.row)} />
                </div>
            )
        },
    ];

    return (
        <div>
            <div>
                {!successfulMessageLoading ?
                    <AppMessageModel open={successfulMessage} setOpen={setSuccessfulMessage} title={title} message={message} />
                    : <div> <CircularLoadingPopUp /></div>
                }
                <div><AppConformationModel open={openConformationModel} setOpen={setOpenConformationModel} title={`${title} ${name}`} subTitle={subTitle} message={message} onConformAction={title === 'Reject' ? onRejectUser : onApproveUser} /></div>
                <div><CreateAndUpdateUser open={openAddUserModel} setOpen={setOpenAddUserModel} onUpdate={editRow} setOnUpdate={setEditRow} isAddOrEditUser={isAddOrEditUser} setIsAddOrEditUser={setIsAddOrEditUser} /></div>
                <div className='d-flex justify-between flex-direction-custom g-3'>
                    <h3><strong>User Approval List</strong></h3>
                    <div className='d-flex justify-between flex-direction-custom g-3'>
                        {localStorage.getItem('user_role') === 'L0' && <Button variant='outlined' className='bg-aliceblue dark-blue m-r-10' onClick={() => setOpenAddUserModel(true)}><strong>
                            <span className='d-flex justify-between g-3'><AddIcon /> Add</span>
                        </strong></Button>}
                        <input style={{ padding: 10, borderRadius: 5, border: '1px solid skyblue' }} placeholder='Search' variant="outlined" onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                </div>
                <div className="b-r-10 p-10 m-t-10" style={{ backgroundColor: 'white'}}><Table rows={filteredData} columns={localStorage.getItem('user_role') === 'L0' ? columnsL0 : columnsL1} loading={!loading} /></div>
            </div>
        </div>
    )
}

export default UsersApprovalList