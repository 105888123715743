import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid aliceblue',
  boxShadow: 24,
  // borderRadius: 3,
  p: 4,
};

function AppMessageModel({ open, setOpen, title, message }) {
  const handleClose = () => { setOpen(!open) }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <b>{title}</b>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
          <Box className='d-flex justify-end'>
            <Button className='m-t-20 bg-dark-blue' style={{ color: 'white', border: 'none', padding: '5px 15px' }} onClick={handleClose}>Close</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default AppMessageModel