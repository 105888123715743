import React, { } from 'react';
import Chart from 'react-apexcharts'

const StackedBarChart = ({seriesData, categoriesData, title, yaxis_title = '', xaxis_title = ''}) => {

  const colors = [
    '#F86F03', '#A76F6F', '#0065bd', "#ffff00", '#00ff00', '#0000ff','#FF5733', '#8B4513', '#8A2BE2', '#32CD32', '#FFD700',
    '#FF6347', '#4682B4', '#8B008B', '#FF69B4', '#00CED1', '#808080', '#800000', '#008080', '#8B0000', '#2F4F4F'
  ];
  // const series = [{
  //   name: 'Grade A',
  //   data: [10, 55, 41, 67]
  // }, {
  //   name: 'Grade B',
  //   data: [13, 23, 20, 8]
  // }, {
  //   name: 'Grade C',
  //   data: [30, 17, 15, 15]
  // },]

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true
      }
    },
    title: {
      text: title ,
      align: 'left',
      style: {
        color: 'gray'
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    xaxis: {
      title: {
        text: xaxis_title,
        style: {
          fontSize: '14px',
          fontWeight: '700',
          // fontFamily: 'bold',
          color: 'gray',
        },
      },
      // categories: ['Wheat', 'Rice', 'Maiza', 'Chana',],
      categories: categoriesData,
      labels: {
        style: {
          colors: colors,
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: yaxis_title,
        margin: 10,
        style: {
          fontSize: '14px',
          fontWeight: '700',
          // fontFamily: 'bold',
          color: 'gray',
        },
      }
    },
    legend: {
      position: 'right',
      offsetY: 40,
      // customLegendItems: [A, B, C, D]
    },
    fill: {
      opacity: 1
    },
    noData: {
      text: 'No Data Available',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#A76F6F',
        fontSize: '14px',
        fontFamily: 'bold'
      }
    }
  }

  return (
    <Chart options={options} series={seriesData} type="bar" height={320} />
  )

}

export default StackedBarChart;
